import React, { useState, useEffect } from "react";
import { useLang } from "./Rooti18n";
import { IntlProvider } from "react-intl";
import axios from "../../axios";
import "@formatjs/intl-relativetimeformat/polyfill";
import { entityNames } from "../../shared/constants";

export const I18nProvider = ({ children }) => {
  const locale = useLang();
  const [messages, setMessages] = useState({});

  useEffect(() => {
    async function fetchTranslations() {
      try {
        const { data: { data }, status } = await axios.get(entityNames.translations);
        if (data && status == 200) {
          setMessages(data[locale]);
        }
      } catch (error) {
        console.log({ ...error });
      }
    }
    fetchTranslations();
  }, []);


  return (
    <IntlProvider locale={locale} messages={messages} onError={(e) => { }}>
      {children}
    </IntlProvider>
  );
}