import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { entityNames } from "../../shared/constants";

import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { VscAccount } from "react-icons/vsc";

import ScrollspyNav from "./scrollSpy";
import logo from "./../../components/LogoSvg/1.svg";

//stickey header
import "react-sticky-header/styles.css";
import StickyHeader from "react-sticky-header";
import { LanguageSelectorDropdown } from "./LanguageSelectorDropdown";
import { Translate } from "@material-ui/icons";

class NavbarPage extends Component {
  state = {
    isOpenMenu: false,
    loading: false,
    uniqueId: "",
    isLoggedIn: false,
  };

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  componentDidMount() {
    const cookie = document.cookie;
    const cookies = cookie.split(";");
    for (let i = 0; i < cookies.length; ++i) {
      const c = cookies[i].split("=")[0];
      if (c === "token") {
        this.setState({ isLoggedIn: true });
      }
    }
  }

  loginWithASAN = async () => {
    try {
      // if (this.state.isLoggedIn) {
      //   window.location = entityNames.Cabinet_Url;
      //   return;
      // }
      // const resp = await axios.get(entityNames.ASAN_Check_Token, {
      //   withCredentials: true,
      // });
      // if (resp.status !== 200) {
      //   throw new Error("Token not provided or token expired");
      // }
      // window.location = entityNames.Cabinet_Url;

      const authorizationAsanUrl = `${
        process.env.REACT_APP_DIGITAL_LOGIN_URL
      }/grant-permission?client_id=${
        process.env.REACT_APP_DIGITAL_LOGIN_CLIENT_ID
      }&redirect_uri=${
        process.env.REACT_APP_CABINET_URL
      }&response_type=code&state=${uuidv4()}&scope=openid certificate`;

      window.location.href = authorizationAsanUrl;
    } catch (err) {
      //  window.location = entityNames.ASAN_Login_Page;
      console.error("loginWithASAN error: ", err);
    }
    // finally {
    //   window.location = entityNames.ASAN_Login_Page;
    // }
  };

  render() {
    //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
    let targetId = this.props.navItems.map((item) => {
      return item.idnm;
    });

    return (
      <Container fluid>
        <div className="row">
          <div className="all">
            <StickyHeader
              header={
                <div>
                  <Navbar
                    expand="lg"
                    fixed="top"
                    className={
                      "navbar-custom sticky sticky-dark " + this.props.navClass
                    }
                  >
                    <Link to="/">
                      <NavbarBrand className="logo text-uppercase" href="/">
                        {/* <img
                        src="/assets/images/logo1.png"
                        style={{ width: 270, height: 95, objectFit: "contain" }}
                      /> */}
                        <img
                          src={logo}
                          className={"logoImage"}
                          style={{
                            width: 310,
                            height: 95,
                            objectFit: "contain",
                            marginRight: "1%",
                          }}
                        />
                      </NavbarBrand>
                    </Link>
                    <NavbarToggler onClick={this.toggle}>
                      <i className="mdi mdi-menu"></i>
                    </NavbarToggler>
                    <>
                      {" "}
                      <Collapse
                        id="navbarCollapse"
                        isOpen={this.state.isOpenMenu}
                        style={{
                          justifyContent: "space-evenly",
                          width: "100%",
                          marginTop: "1.5%",
                        }}
                        navbar
                      >
                        {/* {here} */}
                        <Nav navbar className="navbar-center" id="mySidenav">
                          {/* {this.props.navItems.map((item, key) => (
                          <NavItem
                            key={key}
                            className={
                              item.navheading == "Home" ? "active" : ""
                            }
                          >
                            <Link to={"/" + item.idnm}>
                              <FormattedMessage id={`${item.navheading}`} />
                            </Link>
                          </NavItem>
                        ))} */}

                          <NavItem className={""}>
                            {" "}
                            <Link to={"/home"}>
                              <FormattedMessage id="home" />
                            </Link>
                          </NavItem>

                          <NavItem className={""}>
                            {" "}
                            <Link to={"/qualitymark"}>
                              <FormattedMessage id="qualitymark" />
                            </Link>
                          </NavItem>

                          <NavItem style={{ marginTop: "0%" }} className={""}>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                style={{
                                  background: "none",
                                  border: "none",
                                  fontSize: "17px",
                                  fontFamily: "Gilroy-ExtraBold",
                                  transform: "translateY(-10px)",
                                }}
                              >
                                <Link
                                  to={{
                                    pathname:
                                      "https://app.powerbi.com/view?r=eyJrIjoiNDFhNzI5Y2EtMTEzNC00YTM4LWJiZTAtZmRlMGU5ODEwMTgyIiwidCI6ImEyYzYwMDliLWExNWQtNGMzMC05YzQzLWNiMTkxNjZjNGIwOCIsImMiOjl9&pageName=ReportSection100cf24774034bd28276",
                                  }}
                                  target="_blank"
                                >
                                  <FormattedMessage id="menu_statistics" />
                                </Link>
                              </DropdownToggle>

                              {/* <DropdownMenu>
                                <DropdownItem style={{ backgroundColor: "#7b5393" }}>

                                  <a href="https://public.tableau.com/app/profile/nicat.abbasli/viz/2018-2021Instagrampostsanalysisaze/Dashboard1" target="_blank" ><FormattedMessage id="menu_instagram_analysis" /></a>


                                </DropdownItem>
                                <DropdownItem style={{ backgroundColor: "#7b5393" }}>
                                  <a href="https://app.powerbi.com/view?r=eyJrIjoiN2RhODU1YTQtNjRmYi00MTRmLWI3MGItNWZkOWVkNjI1ZWFiIiwidCI6IjgxYWMzZmNiLTBlMzQtNDJmNS1iZThlLTdiOGQyMjg2MGUxYiIsImMiOjl9&pageName=ReportSection6cb2ca2058a47c7935ed" target="_blank" ><FormattedMessage id="menu_arrival_statistics" /></a>
                                </DropdownItem>

                                <DropdownItem style={{ backgroundColor: "#7b5393" }}>
                                  <a href="https://app.powerbi.com/view?r=eyJrIjoiMGFhNmZjNzItY2Y4OS00MGUxLWEzYzgtZTZkODIzN2U1OWJiIiwidCI6IjgxYWMzZmNiLTBlMzQtNDJmNS1iZThlLTdiOGQyMjg2MGUxYiIsImMiOjl9" target="_blank" ><FormattedMessage id="menu_domestic_tourism" /></a>
                                </DropdownItem>

                              </DropdownMenu> */}
                            </UncontrolledDropdown>
                          </NavItem>

                          {/* <NavItem
                            className={
                              ""
                            }
                          > <Link to={"/advantages"}>
                              <FormattedMessage id="advantages" />
                            </Link>
                          </NavItem> */}

                          <NavItem className={""}>
                            {" "}
                            <Link to={"/services"}>
                              <FormattedMessage id="services" />
                            </Link>
                          </NavItem>

                          <NavItem className={""}>
                            {" "}
                            <Link to={"/missions"}>
                              <FormattedMessage id="missions" />
                            </Link>
                          </NavItem>

                          <NavItem className={""}>
                            {" "}
                            <Link to={"/faq"}>
                              <FormattedMessage id="faq" />
                            </Link>
                          </NavItem>
                        </Nav>
                      </Collapse>
                      <div className="rightButton" style={{ display: "flex" }}>
                        <div
                          style={{ transform: "translateX(45px)" }}
                          className="nav-button d-flex align-items-center nav1"
                          // style={{ marginLeft: "10em" }}
                        >
                          <Nav navbar className="navbar-right">
                            <li className="mb-2">
                              <Button
                                style={{
                                  display: "flex",
                                  padding: "7px 26px",
                                  marginTop: "-6%",
                                }}
                                color="none"
                                type="button"
                                className="btn-custom navbar-btn btn-rounded waves-effect waves-light addedBtn"
                                onClick={this.loginWithASAN}
                              >
                                {!this.state.isLoggedIn ? (
                                  <>
                                    <i
                                      style={{ marginTop: "3.4%" }}
                                      className="pe-7s-lock"
                                    ></i>{" "}
                                    &nbsp;{" "}
                                    <span>
                                      <FormattedMessage id="login" />
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <VscAccount size={20} /> &nbsp;{" "}
                                    <span>
                                      <FormattedMessage id="my_room" />
                                    </span>
                                  </>
                                )}
                              </Button>
                            </li>
                            <li>
                              <LanguageSelectorDropdown />
                            </li>
                          </Nav>
                        </div>

                        {/* <div className="nav-button d-flex align-items-center mt-2 mb-2 nav2" > 
                        <Nav navbar className="navbar-right">
                          <li>
                            <LanguageSelectorDropdown />
                          </li>
                        </Nav>
                      </div> */}
                      </div>
                    </>
                  </Navbar>
                </div>
              }
              stickyOffset={-100}
            ></StickyHeader>
          </div>
        </div>
      </Container>
    );
  }
}

{
  /* <>
<StickyHeader
  header={
    <Navbar
      expand="lg"
      fixed="top"
      className={
        "navbar-custom sticky sticky-dark " + this.props.navClass
      }
    >
      <Container fluid>
        <div className="row">
          <Link to="/">
            <NavbarBrand className="logo text-uppercase" href="/">
              <img
                src="/assets/images/logo1.png"
                style={{ width: 270, height: 95, objectFit: "contain" }}
              />
            </NavbarBrand>
          </Link>
          <NavbarToggler onClick={this.toggle}>
            <i className="mdi mdi-menu"></i>
          </NavbarToggler>
          <>
            {" "}
            <Collapse
              id="navbarCollapse"
              isOpen={this.state.isOpenMenu}
              style={{ justifyContent: "space-evenly" }}
              navbar
            >
              {/* {here} */
}
//               <Nav navbar className="navbar-center" id="mySidenav">
//                 {this.props.navItems.map((item, key) => (
//                   <NavItem
//                     key={key}
//                     className={
//                       item.navheading == "Home" ? "active" : ""
//                     }
//                   >
//                     <Link to={"/" + item.idnm}>
//                       <FormattedMessage id={`${item.navheading}`} />
//                     </Link>
//                   </NavItem>
//                 ))}
//               </Nav>

//               <>
//                 <div
//                   className="nav-button d-flex align-items-center nav1"
//                   // style={{ marginLeft: "10em" }}
//                 >
//                   <Nav navbar className="navbar-right">
//                     <li>
//                       <Button
//                         color="none"
//                         type="button"
//                         className="btn-custom navbar-btn btn-rounded waves-effect waves-light"
//                         onClick={this.loginWithASAN}
//                       >
//                         {!this.state.isLoggedIn ? (
//                           <>
//                             <i className="pe-7s-lock"></i> &nbsp;{" "}
//                             <span>
//                               <FormattedMessage id="login" />
//                             </span>
//                           </>
//                         ) : (
//                           <>
//                             <VscAccount size={20} /> &nbsp;{" "}
//                             <span>
//                               <FormattedMessage id="my_room" />
//                             </span>
//                           </>
//                         )}
//                       </Button>
//                     </li>
//                   </Nav>
//                 </div>

//                 <div className="nav-button d-flex align-items-center mt-2 mb-2 nav2">
//                   <Nav navbar className="navbar-right">
//                     <li>
//                       <LanguageSelectorDropdown />
//                     </li>
//                   </Nav>
//                 </div>
//               </>
//             </Collapse>
//           </>
//         </div>
//       </Container>
//     </Navbar>
//   }
//   stickyOffset={-100}
// ></StickyHeader>
// </> */}

export default withRouter(NavbarPage);
