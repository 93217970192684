import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Container, Row, Col } from "reactstrap";
import { toAbsoluteUrl } from "../../shared/helper";

class FooterLinks extends Component {
  render() {
    return (
      <>
        <div className="footer-alt">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="float-left pull-none ">
                  <p className="copy-rights text-muted">
                    <FormattedMessage id={"footer.dtalink"} />
                    {/* 2022 © Dövlət Turizm Agentliyi */}
                  </p>
                </div>
                {/* <div className="float-right pull-none ">
                  <img
                    src={toAbsoluteUrl("/assets/images/payment.png")}
                    alt="payment"
                    height="36"
                  />
                </div> */}
                <div className="clearfix"></div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default FooterLinks;
