import axios from 'axios';

const instance = axios.create({ baseURL: '/proxy' });

// instance.interceptors.request.use((config) => {
//   const token = sessionStorage.getItem('token');
//   config.headers.Authorization = token ? `Bearer ${token}` : '';
//   return config;
// });

export default instance;
