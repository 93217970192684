const SET_OPEN_ID = "SET_OPEN_ID";
const SET_SEARCH_ITEM = "SET_SEARCH_ITEM";
const initialState = {
  item: null,
  searchItem: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPEN_ID: {
      return { ...state, item: action.item, searchItem: "" };
    }
    case SET_SEARCH_ITEM:
      return { ...state, searchItem: action.item, item: null };
    default: {
      return state;
    }
  }
};
export const setOpenItem = (item) => ({ type: SET_OPEN_ID, item });
export const setSearchItem = (item) => ({ type: SET_SEARCH_ITEM, item });
export default reducer;
