import React, { lazy, Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import Layout from "./components/Layout";
import LoadingComponent from "./components/Loading/Loading";

const Home = lazy(() =>
    import("./pages/Home/Home")
);

const Advantages = lazy(() =>
    import("./pages/Advantages/Advantages")
);

const Services = lazy(() =>
    import("./pages/Services/Services")
);

const Missions = lazy(() =>
    import("./pages/Missions/Missions")
);

const QualityMark = lazy(() =>
    import("./pages/QualityMark/Qualitymark")
);

const Faq = lazy(() =>
    import("./pages/FAQ/Faq")
);

const Categories = lazy(() =>
    import("./pages/Categories/Categories")
);

const StakeholdersMenu = lazy(() =>
    import("./pages/Stakeholders/index")
);

const TourismsMenu = lazy(() =>
    import("./pages/Tourisms/index")
);

const EventsMenu = lazy(() =>
    import("./pages/Events/index")
);

const TrainingMenu = lazy(() =>
    import("./pages/Events/Training/Training")
);

const ResourcesMenu = lazy(() =>
    import("./pages/Resources/index")
);


export function Routes() {
    return (
        <Suspense fallback={<LoadingComponent />}>
            <Layout>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route path='/advantages' component={Advantages} />
                    <Route path='/services' component={Services} />
                    <Route path='/missions' component={Missions} />
                    <Route path='/qualitymark' component={QualityMark} />
                    <Route path='/faq' component={Faq} />
                    <Route path='/categories' component={Categories} />
                    <Route path='/stakeholders' component={StakeholdersMenu} />
                    <Route path='/tourisms' component={TourismsMenu} />
                    <Route path='/events' component={EventsMenu} />
                    <Route path='/trainings' component={TrainingMenu} />
                    <Route path='/resources' component={ResourcesMenu} />
                
                    <Redirect path="*" to="/" />
                </Switch>
            </Layout>
        </Suspense>
    );
}
