import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App.js';
import { RootI18nProvider } from './components/i18n/Rooti18n.js';
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, HttpLink, InMemoryCache } from "@apollo/client";

import store from './redux/store/index';
// import "./sass/style.react.rtl.css"; // RTL version
// import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
// import "socicon/css/socicon.css";
// import "@fortawesome/fontawesome-free/css/all.min.css";
// import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
// import 'react-confirm-alert/src/react-confirm-alert.css';
// Datepicker
// import "react-datepicker/dist/react-datepicker.css";
// import {
//   MetronicLayoutProvider,
//   MetronicSplashScreenProvider,
//   MetronicSubheaderProvider
// } from "./_metronic/layout";
// import { MetronicI18nProvider } from "./_metronic/i18n";
import 'react-phone-input-2/lib/style.css'




const { PUBLIC_URL } = process.env;

const eventLink = new HttpLink({
  uri: '/proxy/event',
  headers: {
    "user": "5f882f1e0411593ba0dad277"
  }
});

const client = new ApolloClient({
  link: eventLink,
  cache: new InMemoryCache()
}); 

const index = (
  <RootI18nProvider>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <App basename={PUBLIC_URL} />
      </ApolloProvider>
    </Provider>
  </RootI18nProvider>
);

ReactDOM.render(index, document.getElementById('root'));
