import React, { useState, Fragment } from "react";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Footer from "../../components/Footer/footer";

const Layout = ({ children }) => {
  const [navItems] = useState([
    { id: 1, idnm: "home", navheading: "home" },
    { id: 5, idnm: "qualitymark", navheading: "qualitymark" },
    { id: 3, idnm: "advantages", navheading: "advantages" },
    { id: 2, idnm: "services", navheading: "services" },
    { id: 4, idnm: "missions", navheading: "missions" },
    { id: 6, idnm: "faq", navheading: "faq" },
  ]);

  return (
    <Fragment>
      <NavbarPage navItems={navItems} />
      {children}
      <Footer />
    </Fragment>
  );
};

export default Layout;
