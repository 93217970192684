import React from "react";
import clsx from "clsx";
import { Dropdown } from "react-bootstrap";
import { useLang, setLanguage } from "../i18n/Rooti18n";
import { toAbsoluteUrl } from "../../services/AssetsHelpers";
// import s from "./../../../public/assets/images/flags/azerbaijan.svg";
import azFlag from "./../../styles/azerbaijan.svg";
import ukFlag from "./../../styles/uk.svg";

const languages = [
  {
    lang: "az",
    name: "az",
    fullName: "Azərbaycanca",
    flag: azFlag,
  },
  {
    lang: "en",
    name: "en",
    flag: ukFlag,
    fullName: "English",
  },
  // {
  //   lang: "ru",
  //   name: "Ru",
  // },
];

export function LanguageSelectorDropdown() {
  const lang = useLang();

  const currentLanguage = languages.find((x) => x.lang === lang);
  
  return (
    <Dropdown style={{margin:"3px 0",
   
    }}>
      <Dropdown.Toggle id="dropdown-basic" className="lang-btn" style={{padding:"7px 9px",marginTop:"-10%"}} >
        <img src={currentLanguage.flag} />
        {/* {currentLanguage.name} */}
      </Dropdown.Toggle>

      <Dropdown.Menu >
        <ul className="lang-menu" style={{width:"170px"}} >
          {languages.map((language) => (
            <li 
              key={language.lang}
              style={{ marginLeft: "1em", marginBottom: "5px" }}
              className={clsx("navi-item", {
                active: language.lang === currentLanguage.lang,
              })}
            >
              <a
                href="#"
                onClick={() => setLanguage(language.lang)}
                className="navi-link"
                style={{ margin: "0px" }}
              >
                <span className="symbol symbol-20 mr-3">
                  <img
                    style={{ height: "2em" }}
                    src={language.flag}
                    alt={language.name}
                  />
                </span>
                <span className="navi-text">{language.fullName}</span>
              </a>
            </li>
          ))}
          {/* {languages.map((language) => (
            <li
              key={language.lang}
              className={clsx("navi-item", {
                active: language.lang === currentLanguage.lang,
              })}
              onClick={() => setLanguage(language.lang)}
            >
              <a href="#" className="navi-link">
                <span className="navi-text">{language.name}</span>
              </a>
              <img src={language.flag} alt={language.name} />
            </li>
          ))} */}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}
