export const toAbsoluteUrl = (pathname) => {
  return window.location.origin + pathname
};

export function normalizeSearchParams(params) {
  const resolvedParams = {};
  if (params) {
    Object.entries(params).forEach(([key, val]) => {
      if (val) resolvedParams[key] = val;
    });
  }
  return resolvedParams;
}

Array.prototype.props = function (
  page = 'details',
  position = 'title',
  one = true
) {
  let cb = (data) =>
    data?.positions && data.positions[page]?.includes(position);
  let result = one ? this.find(cb) : this.filter(cb);
  return result;
};

export const getProps = (
  array = [],
  page = 'details',
  position = 'title',
  one = true
) => {
  let cb = (data) =>
    data?.positions && data.positions[page]?.includes(position);
  let result = one ? array.find(cb) : array.filter(cb);
  return result;
};

String.prototype.embedToObject = function () {
  if (this?.includes('embed')) {
    let coords = [...this.matchAll(/d\d{2}\.\d*/g)];
    return { lat: coords[1][0].slice(1), long: coords[0][0].slice(1) };
  }
};

window.isApple = function () {
  const AppleDevice =
    navigator.platform.indexOf('iPhone') != -1 ||
    navigator.platform.indexOf('iPad') != -1 ||
    navigator.platform.indexOf('iPod') != -1;
  return AppleDevice;
};

window.isApple =
  navigator.platform.indexOf('iPhone') != -1 ||
  navigator.platform.indexOf('iPad') != -1 ||
  navigator.platform.indexOf('iPod') != -1;
