import React, { Component } from "react";

class LoadingComponent extends Component {
  render() {
    return (
      <React.Fragment>
        <div
          className="text-center"
          style={{ marginTop: "15%", marginBottom: "15%" }}
        >
          {" Wait a moment while we load your app..."}
          <div>
            <progress></progress>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default LoadingComponent;
