import React from "react";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { Routes } from "./Routes";
import { I18nProvider } from "./components/i18n/I18nProvider";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckSquare, faCoffee, faMap } from "@fortawesome/free-solid-svg-icons";
library.add(faCheckSquare, faCoffee, faMap);


export default function App({ basename }) {
  return (
    <BrowserRouter basename={basename}>
      <SnackbarProvider maxSnack={4}>
        <I18nProvider>
          <ScrollToTop />
          <Routes />
        </I18nProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
}
