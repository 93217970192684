import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form } from "reactstrap";
// import { mdiYoutube } from "@mdi/js";

//Import Footer link
import FooterLinks from "./footer-links";

class Footer extends Component {
  state = {
    links: [
      {
        title: "key_menu1",
        child: [
          {
            link: "https://meetinazerbaijan.com",
            title: "meetinazerbaijan.com",
          },
          { link: "https://azerbaijan.travel/", title: "azerbaijan.travel" },
          {
            link: "https://tourism.gov.az/",
            title: "tourism.gov.az",
          },
          // {
          //   link: "https://sta.gov.az",
          //   title: "Sta.gov.az",
          // },
          {
            link: "https://tourismboard.az/az",
            title: "tourismboard.az",
          },
        ],
      },
      {
        title: "key_menu2",
        child: [
          { link: "/", title: "home" },
          { link: "/qualitymark", title: "qualitymark" },
          { link: "/advantages", title: "advantages" },
          { link: "/services", title: "services" },
          { link: "/missions", title: "missions" },
          { link: "/faq", title: "faq" },
        ],
      },
    ],
  };
  render() {
    return (
      <>
        <footer className="footer">
          <Container>
            <Row>
              {this.state.links.map((fLink, key) => (
                <Col key={key} lg={4} className="margin-t-20">
                  <h4>
                    <FormattedMessage id={`${fLink.title}`} />
                  </h4>
                  <div className="text-muted margin-t-20">
                    <ul className="list-unstyled footer-list">
                      {fLink.child.map((fLinkChild, key) => (
                        <li key={key}>
                          {fLinkChild.link.startsWith("https") ? (
                            <a
                              href={fLinkChild.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FormattedMessage id={`${fLinkChild.title}`} />
                            </a>
                          ) : (
                            <Link
                              to={fLinkChild.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FormattedMessage id={`${fLinkChild.title}`} />
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              ))}

              <Col lg={4} className="margin-t-20">
                <h4>
                  <FormattedMessage id="key_contact" />
                </h4>

                <div className="text-muted margin-t-25">
                  <div
                    className="contact-title"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="pe-7s-call"></i>
                    <a
                      href="tel:+994554556789"
                      style={{
                        textDecoration: "none",
                        color: "unset",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      +994 12 505 87 04
                    </a>
                  </div>

                  <div
                    className="contact-title"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i className="pe-7s-mail-open"></i>

                    <a
                      href="mailto:support@tourism.az"
                      className="text-muted"
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {/* support@tourism.az */}
                      {/* info@tourism.gov.az */}
                      support@tourism.gov.az
                    </a>
                  </div>
                  <div
                    className="contact-title"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <i
                      className=" mdi mdi-map-marker-radius"
                      aria-hidden="true"
                    ></i>

                    <a
                      // href="mailto:support@tourism.az"
                      className="text-muted"
                      style={{
                        textDecoration: "none",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {/* support@tourism.az */}
                      <FormattedMessage id={"footer.address"} />
                    </a>
                  </div>
                </div>

                <ul
                  className="list-inline social"
                  style={{ marginTop: "32px" }}
                >
                  <li className="list-inline-item">
                    <Link
                      to={{
                        pathname: "https://www.facebook.com/AzerbaijanSTA/",
                      }}
                      target="_blank"
                      className="social-icon"
                    >
                      <i className="mdi mdi-facebook"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to={{ pathname: "https://twitter.com/AzerbaijanSTA" }}
                      target="_blank"
                      className="social-icon"
                    >
                      <i className="mdi mdi-twitter"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to={{
                        pathname:
                          "https://www.linkedin.com/company/azerbaijansta/",
                      }}
                      target="_blank"
                      className="social-icon"
                    >
                      <i className="mdi mdi-linkedin"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to={{
                        pathname: "https://www.instagram.com/azerbaijan_sta/",
                      }}
                      target="_blank"
                      className="social-icon"
                    >
                      <i className="mdi mdi-instagram"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to={{
                        pathname: "https://www.youtube.com/c/AzerbaijanSTA",
                      }}
                      target="_blank"
                      className="social-icon"
                    >
                      {/* <span className="iconify" data-icon="mdi:youtube"></span> */}
                      {/* <span className="mdi mdi-youtube"></span> */}
                      <span className="mdi mdi-youtube-play"></span>
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </footer>

        <FooterLinks />
      </>
    );
  }
}

export default Footer;
