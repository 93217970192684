import React from "react";
import { useLang } from "../components/i18n/Rooti18n";
import { FormattedMessage } from "react-intl";
import image1 from "./../images/1.svg";
import image2 from "./../images/2.svg";
import image3 from "./../images/3.svg";
import image4 from "./../images/4.svg";
import { Rating } from "@material-ui/lab";
const host = window.location.host;
const localhost = ["localhost:3000", "localhost:3001", "localhost:3002"];

const currentLocalhost = localhost.find((l) => l.includes(host));

export const entityNames = {
  industryCategory: "/industry-category",
  industrySubCategories: "/industry-sub-category",
  stakeholder: "/stakeholder",
  criterias: "/criteria",
  criteriaQuestions: "/criteria-question",
  gradeApplication: "/grade-application",
  translations: "/translation/getAll",
  person: "/person",
  ASAN_Check_Token:
    host === currentLocalhost
      ? "https://asanlogintest.my.gov.az/ssoauthz/api/v1/token"
      : host === "registry.agilesolutions.az"
      ? "https://asanlogintest.my.gov.az/ssoauthz/api/v1/token"
      : "https://asanlogin.my.gov.az/ssoauthz/api/v1/token",
  ASAN_Login_Page:
    host === currentLocalhost
      ? "https://portal.login.gov.az/auth?origin=https://cabinet.registry.agilesolutions.az"
      : host === "registry.agilesolutions.az"
      ? "https://portal.login.gov.az/auth?origin=https://cabinet.registry.agilesolutions.az"
      : "https://asanlogin.my.gov.az/auth?origin=https://data-cabinet.tourism.gov.az",
  ASAN_Get_Certs:
    host === currentLocalhost
      ? "https://portal.login.gov.az/ssoauthz/api/v1/token/certs"
      : host === "registry.agilesolutions.az"
      ? "https://portal.login.gov.az/ssoauthz/api/v1/token/certs"
      : "https://apiasanlogin.my.gov.az/ssoauthz/api/v1/token/certs",
  ASAN_Post_BusinessSignCertificate:
    "/certs/signin-certificates/getBusinessSigningCertificate",
  Cabinet_Url:
    host === currentLocalhost
      ? currentLocalhost
      : host === "registry.agilesolutions.az"
      ? "https://cabinet.registry.agilesolutions.az"
      : "",
};

export const renderStakeholderAnswers = (data, selectedLang) => {
  return data?.props?.map(({ name, value, htmlType }) => {
    switch (htmlType) {
      case "select":
        return (
          <div>
            <div className="mt-2">
              <strong>{name?.[selectedLang]}: </strong>
            </div>
            <div style={{ wordBreak: "break-word" }}>{value}</div>
          </div>
        );
      // case "rating":
      //   return (
      //     <div>
      //       <div className="mt-2">
      //         <b>{name[selectedLang]}: </b>
      //       </div>
      //       <div style={{ wordBreak: "break-word" }}>{value}</div>
      //     </div>
      //   );
      case "multi-select":
        return (
          <div>
            {value && value !== "" && (
              <>
                <div className="mt-2">
                  <strong>{name[selectedLang]}: </strong>
                </div>
                {value?.length
                  ? value?.map((v) => (
                      <div style={{ wordBreak: "break-word" }}>
                        <FormattedMessage id={`${v}`} />
                      </div>
                    ))
                  : null}
              </>
            )}
          </div>
        );
      case "multi-select-files":
        return (
          <div>
            {value && value !== "" && (
              <>
                <div className="mt-2">
                  <strong>{name?.[selectedLang]}: </strong>
                </div>
                <div style={{ wordBreak: "break-word" }}>
                  {value?.options.map((v) => (
                    <div style={{ display: "block" }}>
                      {" "}
                      <FormattedMessage id={v} />
                    </div>
                  ))}
                </div>
                <div>
                  <div>
                    {value?.files?.map((img) => (
                      <img
                        src={`/proxy/stakeholder/getFile/${img?.url}`}
                        alt=""
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case "location":
        return (
          <div>
            {value && value !== "" && (
              <>
                <div className="mt-2">
                  <strong>{name[selectedLang]}: </strong>
                </div>
                <div style={{ wordBreak: "break-word" }}>{value}</div>
              </>
            )}
          </div>
        );
      case "link":
        return (
          <div>
            {value && value !== "" && (
              <>
                <div className="mt-2">
                  <strong>{name[selectedLang]}: </strong>
                </div>
                <div style={{ wordBreak: "break-word" }}>{value}</div>
              </>
            )}
          </div>
        );
      case "translatable":
        return (
          <div>
            {!value && (
              <>
                <div className="mt-2">
                  <strong>{name[selectedLang]}: </strong>
                </div>
                {
                  <div style={{ wordBreak: "break-word" }}>
                    {value?.[selectedLang]}
                  </div>
                }
              </>
            )}
          </div>
        );
      case "text":
        return (
          <div>
            {value && value !== "" && (
              <>
                <div className="mt-2">
                  <strong>{name[selectedLang]}: </strong>
                </div>
                {<div style={{ wordBreak: "break-word" }}>{value}</div>}
              </>
            )}
          </div>
        );
      case "files":
        return (
          <div>
            {value && value !== "" && (
              <>
                <div className="mt-2">
                  <strong>{name[selectedLang]}: </strong>
                </div>
                {<div style={{ wordBreak: "break-word" }}>{value}</div>}
              </>
            )}
          </div>
        );
      // case "rating":
      //   return (
      //     <div>
      //       {value && value !== "" && (
      //         <>
      //           {value && <Rating
      //             className="stakeholder__content__rating"
      //             defaultValue={2}
      //             value={!isNaN(value) ? Number(value) : 0}
      //             readOnly
      //           />}
      //         </>
      //       )}
      //     </div>
      //   );
      default:
        return <div>{/* <FormattedMessage id="no.result.found" /> */}</div>;
    }
    // <div>
    //   <div className="mt-2"><b>{name[selectedLang]}: </b></div>
    //   <div>{value}</div>
    // </div>
  });
};
export const Grades = {
  NEW: "E",
  D: "D",
  C: "C",
  B: "B",
  A: "A",
};

export const FILE_SIZE = 5242880;
export const SUPPORTED_FILE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "application/pdf",
];
export const SUPPORTED_PHOTO_TYPES = ["image/png", "image/jpeg", "image/jpg"];

export const HtmlInputTypes = {
  TEXT: "text",
  TEXTAREA: "textarea",
  LOCATION: "location",
  NUMBER: "number",
  EMAIL: "email",
  PHONE: "phone",
  SELECT: "select",
  MULTISELECT: "multi-select",
  DATE: "date",
  RATING: "rating",
  FILE: "file",
  FILES: "files",
  TOGGLE: "toggle",
  SLIDER: "slider",
  RANGE: "range",
  TIME_RANGE: "time-range",
  COUNTRY_FLAGS: "country-flags",
};

export const ValidationTypes = {
  STRING: "string",
  NUMBER: "number",
  FILE: "file",
  FILES: "files",
  EMAIL: "email",
  ARRAY: "array",
  BOOLEAN: "boolean",
  DATE: "date",
  URL: "url",
  LOCATION: "location",
  TIME_RANGE: "time-range",
  SLIDER: "slider",
};

export const Position = {
  TITLE: "title",
  DESCRIPTION: "description",
  BOOKING_COM_RATING: "booking_com_rating",
  RATING: "rating",
  PHOTO: "photo",
  ASIDE: "aside",
};

export const PositionTypes = {
  TITLE: "title",
  PHOTOS: "photos",
  RATING: "rating",

  LOCATION: "location",
  ADDRESS: "address",

  FACILITIES: "facilities",
  SERVICES: "services",

  POINT: "point",

  PRICES: "prices",
  ROOM: "room",

  WORKING: "working",
  TOURISTS: "tourists",
  GENERAL: "general",
  CAPACITY: "capacity",

  SCORES: "scores",
  MAP: "map",
  SOCIAL: "social",

  BODY: "body",
  CONTACT: "contact",
};

export const ComponentTypes = {
  RATING: "rating",
  FACILITIES: "facilities",
  MAP: "map",
  SCORE: "score",
  SOCIAL: "social",
};

export const SocialIcons = {
  TELEGRAM: "telegram",
  WHATSAPP: "whatsapp",
  LINE: "line",
  VIBER: "viber",
  TWITTER: "twitter",
  PERISCOPE: "periscope",
  FACEBOOK: "facebook",
  MESSENGER: "messenger",
  INSTAGRAM: "instagram",
  LINKEDIN: "linkedin",
  PINTEREST: "pinterest",
  WIKIPEDIA: "wikipedia",
  GOOGLEPLUS: "googleplus",
  AIRBNB: "airbnb",
  TUMBLR: "tumblr",
  TWITCH: "twitch",
  YOUTUBE: "youtube",
  VIMEO: "vimeo",
  YANDEX: "yandex",
  VINE: "vine",
  VKONTAKTE: "vkontakte",
  REDDIT: "reddit",
  DISCORD: "discord",
  FLIPBOARD: "flipboard",
  GIT: "git",
  GITHUB: "github",
  WEBSITE: "website",
};

export const AlertModes = {
  SUCCESS: { variant: "success" },
  ERROR: { variant: "error" },
  WARNING: { variant: "warning" },
  INFO: { variant: "info" },
};

export const registeredItems = [
  {
    image: "assets/images/registered/stakeholders.png",
    title: "menu_stakeholders",
    subCategories: [
      {
        id: "60d45f437332ac350d373368",
        name: "menu_hotels",
        url: "/stakeholders/accomodations",
      },
      // {
      //   id: "2",
      //   name: "menu_restaurants",
      //   url: "/stakeholders/restaurants",
      // },
      {
        id: "3",
        name: "menu_tour_operator_travel",
        url: "/stakeholders/ta_to",
      },
      {
        id: "4",
        name: "menu_guides",
        url: "/stakeholders/guides",
      },
    ],
    url: "/stakeholders",
  },
  {
    image: "assets/images/registered/tourism_oportiunity.png",
    title: "menu_tourism_oportiunity",
    subCategories: [
      {
        id: "1",
        name: "key_tourism_source",
        url: "/resources",
      },
      {
        id: "2",
        name: "menu.products",
        url: "",
      },
      {
        id: "3",
        name: "key_tourism_objects",
        url: "",
      },
    ],
    url: "/tourisms",
  },
  {
    image: "assets/images/registered/training_event.png",
    title: "menu_training_event",
    subCategories: [
      {
        id: "1",
        name: "menu.trainings",
        url: "/events/training",
      },
      {
        id: "2",
        name: "menu.events",
        url: "/events/event",
      },
    ],
    url: "/events",
  },
];

export const johnImage = {
  image: "../../assets/images/restaurant/john.png",
};

export const janeImage = {
  image: "../../assets/images/restaurant/jane.png",
};

export const restaurantDatas = [
  {
    id: "0",
    image: "../assets/images/restaurant/kolorit.jpg",
    title: "Kolorit Baku Restaurant",
    rating: 5,
    location: "Azerbaijan, Baku, Targovu, Nizami 83",
  },
  {
    id: "1",
    image: "../assets/images/restaurant/oldcity.jpg",
    title: "Old City Restaurant",
    rating: 4,
    location: "Azerbaijan, Baku, 9R7P+W7F ",
  },
  {
    id: "2",
    image: "../assets/images/restaurant/central.jpg",
    title: "Central Baku Restaurant",
    rating: 2.5,
    location: "Azerbaijan, Baku 75 Nizami St, Baku 1005",
  },
];

// export const TaToDatas = [
//   {
//     id: "0",
//     image: "../assets/images/Guide/caspi.jpg",
//     title: "Caspi Tours",
//     rating: 4,
//     location: "Azerbaijan, Baku",
//   },
//   {
//     id: "1",
//     image: "../assets/images/Guide/aze.jpg",
//     title: "Azerbaijan Travel",
//     rating: 4,
//     location: "Azerbaijan, Baku",
//   },
//   {
//     id: "2",
//     image: "../assets/images/Guide/granat.png",
//     title: "Granat Travel LLC",
//     rating: 4,
//     location: "Azerbaijan, Baku",
//   },
// ];

export const GuideDatas = [
  {
    id: "0",
    image: "../assets/images/Guide/nurlan.png",
    title: "Nurlan Abbasov",
    language: "Az , Tr , En",
    rating: 4,
    agat: "yoxdu",
    private: "Baku",
    Areas:
      "Quba, Shaki, Qabala, Ismayilli, Qusar, Shamakhi, Ganja, Gobustan National Park, Absheron",
    location:
      "I have over 4 years experience. I took up this work as a career because I feel that one should work in a capacity where one enjoys work. And the fact that I love and enjoy my work immensely can be shown in the way I lead tours. Specifically, I am well-versed in leading groups on sightseeing tours, providing them with information on historical and cultural significances of different areas within the city, and in its outskirts. Since I believe that a well-organized tour is a successful one, I make sure that all areas such as timelines and safety measures are well taken care of. My enthusiasm and communication skills have allowed me to act confidently as a tour guide, developing strong relationships with both clients and local communities. • Takes charge of meeting clients at the airport, train station, or bus terminal • Ensures that tourists and travelers are comfortably settled in their hotels or resorts. • Explains the itinerary and schedule of the travel • Scheduling visits and purchasing tickets to museums, galleries, protected parks, and other attractions ahead of time, if required • Provides significant information about each destination or attraction visited with emphasis on its cultural and historical meaning • Developed program itineraries depending on the needs of clients • Monitored weather conditions and provide such information to other team members • Handles emergency situations; manage sudden changes or modifications to the tour • Prepared reports on the tour to the supervising travel agent.",
  },
  {
    id: "1",
    image: "../assets/images/Guide/aida.png",
    title: "Aida Bayramova",
    language: "Az, En, Fr, Ru, Tr",
    rating: 5,
    agat: "yoxdu",
    private: "Baku",
    Areas: "Baku",
    location:
      "I am a professional tourist guide based in Baku, Azerbaijan, licensed by the Azerbaijan Ministry of Culture and Tourism and by the WFTGA (World Federation of Tourist Guide Associations). With personalized experience to visitors from many countries, I conduct tours in English, Russian, Turkish and French. If you are planning to visit Azerbaijan, I welcome you to the magical Land ! As a tourist guide with a strong experience from Turkey and being a masters degree student at Azerbaijan Tourism Institute, I will try to do my best and to make you feel satisfied. With me you will feel yourself special and enjoy every part of Azerbaijan and understand its traditional value.",
  },
  {
    id: "2",
    image: "../assets/images/Guide/jamil.png",
    title: "Jamil Heyderli",
    language: "Ar, Az, En, Ru , Sp, Tr",
    rating: 3,
    agat: "yoxdu",
    private: "Baku",
    Areas: "Shaki, Gobustan National Park",
    location:
      " I am punctual, hard-working, open-minded, tolerant, lovely and friendly  I am 24 years old. I love mountain and adventure sports. I travel through mountains and wonderful places in Azerbaijan.  ",
  },
];

export const tourismsItems = [
  {
    image: "../assets/images/registered/img7.jpg",
    title: "key_tourism_source",
    desc: "",
    url: "/resources",
  },
  {
    image: "../assets/images/registered/img8.jpg",
    title: "menu.products",
    desc: "",
    // url: "/*",
  },
  {
    image: "../assets/images/registered/img9.jpg",
    title: "key_tourism_objects",
    desc: "",
    // url: "/*",
  },
  // {
  //   image: "../assets/images/registered/img1.jpg",
  //   title: "Resurslar",
  //   desc: "",
  //   url: "/*",
  // },
];

export const eventsItems = [
  {
    image: "../assets/images/registered/Training.png",
    title: "menu.trainings",
    desc: "",
    url: "/events/training",
  },
  {
    image: "../assets/images/registered/events2.jpg",
    title: "menu.events",
    desc: "",
    url: "/events/event",
  },
];

export const loadingTypes = {
  balls: "balls",
  bars: "bars",
  bubbles: "bubbles",
  cubes: "cubes",
  cylon: "cylon",
  spin: "spin",
  spinningBubbles: "spinningBubbles",
  spokes: "spokes",
};

export const countries = {
  au: "/assets/images/flags/australia.svg",
  az: "/assets/images/flags/azerbaijan.svg",
  bh: "/assets/images/flags/bahrain.svg",
  bd: "/assets/images/flags/bangladesh.svg",
  by: "/assets/images/flags/belarus.svg",
  bg: "/assets/images/flags/bulgaria.svg",
  ca: "/assets/images/flags/canada.svg",
  cl: "/assets/images/flags/chile.svg",
  cn: "/assets/images/flags/china.svg",
  hr: "/assets/images/flags/croatia.svg",
  cz: "/assets/images/flags/czech-republic.svg",
  dk: "/assets/images/flags/denmark.svg",
  eg: "/assets/images/flags/egypt.svg",
  ee: "/assets/images/flags/estonia.svg",
  fi: "/assets/images/flags/finland.svg",
  fr: "/assets/images/flags/france.svg",
  ge: "/assets/images/flags/georgia.svg",
  de: "/assets/images/flags/germany.svg",
  gr: "/assets/images/flags/greece.svg",
  hu: "/assets/images/flags/hungary.svg",
  ic: "/assets/images/flags/iceland.svg",
  in: "/assets/images/flags/india.svg",
  id: "/assets/images/flags/indonesia.svg",
  iq: "/assets/images/flags/iraq.svg",
  ir: "/assets/images/flags/iran.svg",
  ie: "/assets/images/flags/ireland.svg",
  il: "/assets/images/flags/israel.svg",
  it: "/assets/images/flags/italy.svg",
  jp: "/assets/images/flags/japan.svg",
  kz: "/assets/images/flags/kazakhstan.svg",
  kw: "/assets/images/flags/kuwait.svg",
  kg: "/assets/images/flags/kyrgyzstan.svg",
  qa: "/assets/images/flags/qatar.svg",
  lv: "/assets/images/flags/latvia.svg",
  lb: "/assets/images/flags/lebanon.svg",
  ly: "/assets/images/flags/libya.svg",
  li: "/assets/images/flags/liechtenstein.svg",
  lu: "/assets/images/flags/luxembourg.svg",
  my: "/assets/images/flags/malaysia.svg",
  mx: "/assets/images/flags/mexico.svg",
  md: "/assets/images/flags/moldova.svg",
  me: "/assets/images/flags/montenegro.svg",
  ma: "/assets/images/flags/morocco.svg",
  nz: "/assets/images/flags/new-zealand.svg",
  ne: "/assets/images/flags/niger.svg",
  ng: "/assets/images/flags/nigeria.svg",
  no: "/assets/images/flags/norway.svg",
  om: "/assets/images/flags/oman.svg",
  pk: "/assets/images/flags/pakistan.svg",
  ps: "/assets/images/flags/palestine.svg",
  ph: "/assets/images/flags/philippines.svg",
  pl: "/assets/images/flags/poland.svg",
  pt: "/assets/images/flags/portugal.svg",
  mk: "/assets/images/flags/north-macedonia.svg",
  ro: "/assets/images/flags/romania.svg",
  ru: "/assets/images/flags/russia.svg",
  sa: "/assets/images/flags/saudi-arabia.svg",
  rs: "/assets/images/flags/serbia.svg",
  sg: "/assets/images/flags/singapore.svg",
  sk: "/assets/images/flags/slovakia.svg",
  si: "/assets/images/flags/slovenia.svg",
  za: "/assets/images/flags/south-africa.svg",
  kr: "/assets/images/flags/south-korea.svg",
  sp: "/assets/images/flags/spain.svg",
  lk: "/assets/images/flags/sri-lanka.svg",
  se: "/assets/images/flags/sweden.svg",
  ch: "/assets/images/flags/switzerland.svg",
  sy: "/assets/images/flags/syria.svg",
  tj: "/assets/images/flags/tajikistan.svg",
  th: "/assets/images/flags/thailand.svg",
  tr: "/assets/images/flags/turkey.svg",
  tm: "/assets/images/flags/turkmenistan.svg",
  gb: "/assets/images/flags/uk.svg",
  ua: "/assets/images/flags/ukraine.svg",
  ae: "/assets/images/flags/united-arab-emirates.svg",
  us: "/assets/images/flags/usa.svg",
  uz: "/assets/images/flags/uzbekistan.svg",
  vn: "/assets/images/flags/vietnam.svg",
  ye: "/assets/images/flags/yemen.svg",
};

export const advantagesItems = [
  { url: "register.png", content: "registration", to: "register" },
  { url: "level.png", content: "register_system", to: "valuation" },
  { url: "register.png", content: "categories_system", to: "categories" },
];

export const categoriesItems = [
  {
    _id: "1",
    url: "./gradeCategories/register.png",
    content: "register_advantages",
  },
  {
    _id: "2",
    url: "./gradeCategories/evaluation.png",
    content: "register_system",
  },
  {
    _id: "3",
    url: "./gradeCategories/quality_marks.png",
    content: "label.labels",
  },
  {
    _id: "4",
    url: "./gradeCategories/industry_subjects.png",
    content: "label.industry.subjects",
  },
  {
    _id: "5",
    url: "./gradeCategories/tourism_opportiunity.png",
    content: "menu_tourism_oportiunity",
  },
  {
    _id: "6",
    // url: "./gradeCategories/levels.png",
    // content: "key_statics",
    url: "./gradeCategories/Calendar.png",
    content: "key_events_trainings",
  },
  {
    _id: "7",
    // url: "./gradeCategories/Calendar.png",
    // content: "key_events_trainings",
    url: "./gradeCategories/levels.png",
    content: "key_statics",
  },
  {
    _id: "8",
    url: "./gradeCategories/monitoring.png",
    content: "men.checking_module",
  },
  { _id: "9", url: "./gradeCategories/map.png", content: "key_map" },
  {
    _id: "10",
    url: "./gradeCategories/map.png",
    content: "key_guests_registration",
  },
  {
    _id: "11",
    url: "./gradeCategories/tourism_opportiunity.png",
    content: "key_general",
  },
];

export const accordionItems = [
  {
    _id: "1",
    key: "registration",
    list_name: "event_accordion_name_1",
    desc: [
      {
        question: "faq.registry_question_1",
        answer: "faq.registry_answer_1",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },
      {
        question: "faq.registry_question_2",
        answer: "faq.registry_answer_2",
        // question:
        //   "İdentifikasiyadan sonra ASAN İmza funksiyası ilə qeyd və şərtlərin təsdiqlənməsi prosesi vacibdirmi?",
        // answer:
        //   "Müvafiq proses məlumat mübadiləsi üzrə hər iki tərəfin qarşılıqlı məsuliyyətlərinin müəyyən edilməsi baxımından əhəmiyyətlidir. Qeyd və şərtlər, fərdi və kommersiya məlumatlarının ötürülməsi və qorunmasının hüquqi əsaslarını əks etdirir.",
      },
      // {
      //   question: "Qeydiyyat hissəsində hansı məlumatlar tələb olunur?",
      //   answer: `Qeydiyyat şablonunda aşağıda əks olunan məlumatlar tələb olunur:
      //   -	Rəhbərin adı, soyadı və atasının adı
      //   -	Əlaqə məlumatları
      //   -	İcra orqanının rəhbəri (adı, soyadı, atasının adı, vəzifəsi)
      //   -	Obyektin istismara başlama tarixi, əlaqə məlumatları
      //   -	Brendin adı
      //   -	Elektron poçt ünvanı
      //   -	Obyektin əlaqə nömrələri
      //   -	İnternet səhifə
      //   -	Faktiki ünvan
      //   -	Müəssisənin/şirkətin loqosu

      //     `,
      // },
      {
        question: "faq.registry_question_3",
        answer: "faq.registry_answer_3",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },
      {
        question: "faq.registry_question_4",
        answer: "faq.registry_answer_4",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },
      // {
      //   question:
      //     "Bir VÖEN identifikasiyası ilə hansı sayda obyekt qeydiyyata almaq mümkündür?",
      //   answer:
      //     "Bir fərd üzrə 3 qeydiyyat (fərdi identifikasiya nömrəsi, fiziki və hüquqi şəxs əsaslı) həyata keçirmək mümkündür. Turizm sənayesi nümayəndələrindən sadəcə bələdçilər üzrə fərdi identifikasiya əsaslı qeydiyyatı tamamlamaq aktivləşdirilmişdir. Fiziki və hüquqi şəxs olaraq qeydiyyatdan keçərkən “n” sayda obyekt yaradılması imkanı mövcuddur. ",
      // },
      // {
      //   question: "VÖEN üzrə obyektləri necə müəyyən etmək mümkündür?",
      //   answer:
      //     "Sistem Dövlət Vergi Xidmətinin AVİS sistemi ilə inteqrasiya olunduğundan turizm fəaliyyət istiqamətinə sahib vergi ödəyicilərinin obyekt məlumatları avtomatlaşdırılmış olaraq obyekt seçimində əks olunacadır.",
      // },
      {
        question: "faq.registry_question_5",
        answer: "faq.registry_answer_5",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },
      {
        question: "faq.registry_question_6",
        answer: "faq.registry_answer_6",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },

      // {
      //   question: "Eyni obyekt üzrə qeydiyyat mümkündürmü?",
      //   answer:
      //     "Eyni VÖEN üzrə obyekt nömrəsi təkrarlanarsa qeydiyyat prosesində “sənaye nümayəndəsi mövcuddur” bildirişi ilə qarşılaşacaqsınız. Qeydiyyatı tamamlamaq üçün sistem tərəfindən avtomatlaşdırılmış şəkildə təmin edilən obyekt adlarından birini seçməlisiniz. Eyni obyekt üzrə qeydiyyat keçmək istədiyinizdə öncə yaradılmış səhifəyə yönləndiriləcəksiniz.",
      // },
      // {
      //   question: "VÖEN üzrə obyektləri necə müəyyən etmək mümkündür?",
      //   answer:
      //     "Sistem Dövlət Vergi Xidmətinin AVİS sistemi ilə inteqrasiya olunduğundan turizm fəaliyyət istiqamətinə sahib vergi ödəyicilərinin obyekt məlumatları avtomatlaşdırılmış olaraq obyekt seçimində əks olunacaqdır.",
      // },
      {
        question: "faq.registry_question_7",
        answer: "faq.registry_answer_7",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },
      // {
      //   question: "Eyni obyekt üzrə qeydiyyat mümkündürmü?",
      //   answer:
      //     "Eyni VÖEN üzrə obyekt nömrəsi təkrarlanarsa. qeydiyyat prosesində “sənaye nümayəndəsi mövcuddur” bildirişi ilə qarşılaşacaqsınız. Qeydiyyatı tamamlamaq üçün sistem tərəfindən avtomatlaşdırılmış şəkildə təmin edilən obyekt adlarından birini seçməlisiniz. Eyni obyekt üzrə qeydiyyatdan keçmək istədiyinizdə öncə yaradılmış səhifəyə yönləndiriləcəksiniz.",
      // },
      {
        question: "faq.registry_question_8",
        answer: "faq.registry_answer_8",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },

      {
        question: "faq.registry_question_9",
        answer: "faq.registry_answer_9",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },
      // {
      //   question: "Qeydiyyata müraciət etdikdə nə baş verir?",
      //   answer:
      //     "Qeydiyyat tamamlandıqdan sonra müraciət, baxılması üçün müvafiq qurumun nümayəndəsinə göndərilir. Sorğu düzgün formalaşdırılmış olduğu təqdirdə təsdiqlənir. Bununla turizm sənayesi nümayəndəsinin Turizm Reyestrində əks olunması təmin edilir. Nəticə olaraq reyestr üzvünün müxtəlif dövlət sistemləri (AVİS, EHİS) üzrə mövcudluğunun verifikasiyası aparılır.",
      // },

      {
        question: "faq.registry_question_10",
        answer: "faq.registry_answer_10",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },
      // {
      //   question:
      //     "Qeydiyyatı tamamlamaq, hər hansı bir nişan əldə etmək imkanı yaradırmı?",
      //   answer:
      //     "Qeydiyyatı tamamlayan turizm sənayesi nümayəndələri ümumi kateqoriyalar üzrə “D” nişanı əldə edir.",
      // },

      // {
      //   question:
      //     "Qeydiyyat tamamlandıqdan sonra hər hansı bir bildiriş əldə edilir mi?",
      //   answer:
      //     "Qeydiyyat tamamlandıqdan sonra turizm sənayesi nümayəndəsi email vasitəsi ilə rəsmi bildiriş formasına uyğun elektron bildiriş qəbul edir. Bildirişdə Turizm sənayesi üzvünün adı, növü, qeydiyyat nömrəsi (kod) və qeydiyyat tarixi kimi məlumatlar əhatə olunur. ",
      // },

      {
        question: "faq.registry_question_11",
        answer: "faq.registry_answer_11",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },
      {
        question: "faq.registry_question_12",
        answer: "faq.registry_answer_12",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },
      // {
      //   question: "Şəxsi kabinetə 1-dən çox şəxsin girməsi mümkündürmü?",
      //   answer:
      //     "Şəxsi kabinetə, ASAN İmza üzrə möhür vurma səlahiyyəti ötürülmüş hər hansı bir istifadəçi daxil olmaq imkanına sahibdir.",
      // },

      // {
      //   question: "Qeydiyyat məlumatlarını yeniləmək mümkündürmü?",
      //   answer:
      //     "Qeydiyyat şablonunda avtomatlaşdırılmış şəkildə gələn məlumatlardan başqa fərdi şəkildə daxil edilən bütün məlumatların yenilənməsi mümkündür. Müvafiq təsdiqdən sonra hesab üzrə məlumat güncəllənməsi prosesi tamamlanır.",
      // },
      {
        question: "faq.registry_question_13",
        answer: "faq.registry_answer_13",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },
      {
        question: "faq.registry_question_14",
        answer: "faq.registry_answer_14",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },
      // {
      //   question: "Birdən çox qeydiyyat necə idarə olunur?",
      //   answer:
      //     "Qeydiyyat apardığınız obyektlər bir VÖEN altında yığılmaqdadır. Müxtəlif VÖEN-lərlə fəaliyyət göstərirsinizsə, identifikasiya olunduğunuz VÖEN üzrə obyektlərlə əlaqədar əməliyyat aparmaq imkanına malik olursunuz. ASAN İmza ilə giriş etdiyiniz zaman sistem sizi davam etmək istədiyiniz qeydiyyat obyektinin əks olunduğu səhifəyə yönləndirir. Buradan mövcud obyektinizi seçə, və ya yeni obyekt üzrə qeydiyyatınızı davam edə bilərsiniz. Əlavə olaraq şəxsi kabinetinizdən qeydiyyata aldığınız bütün səhifələrlə əlaqədar əməliyyat aparmaq imkanı əldə edirsiniz. Bunun üçün şəxsi kabinet hissəsindən hesablarım bölməsinə giriş etməyiniz kifayətdir.",
      // },
      {
        question: "faq.registry_question_15",
        answer: "faq.registry_answer_15",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },
      // {
      //   question:
      //     "Məlumatlarla əlaqədar yenilənmələrə neçə iş günü ərzində baxılır?",
      //   answer:
      //     "Baxılmağa göndərilmiş məlumat yeniləmələrinə 5-7 iş günü ərzində sistemdə öz əksini tapır.",
      // },

      // {
      //   question: "Sistem üzrə məlumatları yeniləmədikdə nə baş verir?",
      //   answer:
      //     "Sistem üzrə məlumatlar müvafiq bildiriş sistemi vasitəsiylə xəbərdarlıq əsaslı çalışır. Müvafiq məlumatlar yenilənmədiyi təqdirdə sistem üzrə əldə edilmiş nişanlardan məhrum olma riski yaranır.",
      // },
      {
        question: "faq.registry_question_16",
        answer: "faq.registry_answer_16",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },

      // {
      //   question: "Qeydiyyat deaktivasiya olunurmu?",
      //   answer:
      //     "Heç bir qeydiyyat deaktiv olunmur. Reytinq üzrə əldə edilmiş nəticələr müəyyən vaxt aralığında lazımi tədbirlər görülmədiyi halda sıfırlanır və qiymətləndirmə mərhələsinin ilkin hissəsinə (C kateqoriyasına) qaytarılır.",
      // },
      // {
      //   question: "faq.registry_question_17",
      //   answer: "faq.registry_answer_17",
      //   // answer:
      //   //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      // },

      // {
      //   question: "faq.registry_question_18",
      //   answer: "faq.registry_answer_18",
      //   // answer:
      //   //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      // },
      // {
      //   question:
      //     "Müxtəlif mərhələlərdə məcburi doldurulmalı xanalar mövcuddurmu?",
      //   answer:
      //     "Sistem üzrə məcburi xanalar xüsusi işarələnmişdir. Doldurulmadığı təqdirdə növbəti səhifələrə keçid mümkün olmayacaqdır.",
      // },
    ],
  },
  {
    _id: "2",
    key: "key_classifications",
    list_name: "event_accordion_name_1",
    desc: [
      // {
      //   question: "Klassifikasiyaya necə müraciət olunur?",
      //   answer:
      //     "Klassifikasiyaya, şəxsi kabinetdə istifadəçi adının yer aldığı paneldən “müraciət et” düyməsinə basılaraq müraciət etmək mümkündür.",
      // },
      {
        question: "faq.classification_question_1",
        answer: "faq.classification_answer_1",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },
      // {
      //   question: "Klassifikasiyanın nəticəsi necə müəyyən edilir?",
      //   answer:
      //     "Sorğu şablonu tamamlandıqdan sonra sistem üzrə müvafiq kombinasiyaya uyğunluq, avtomatlaşdırılmış şəkildə yoxlanır və sənaye nümayəndəsinin müraciəti əsaslandırıcı sənədlərlə birgə baxılmağa göndərilir. Əgər müraciət qənaətbəxşdirsə təsdiqlənir və sənaye nümayəndəsi müvafiq kateqoriya üzrə nişan alaraq Turizm Reyestrində statusunu təkmilləşdirir.",
      // },
      {
        question: "faq.classification_question_2",
        answer: "faq.classification_answer_2",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },

      // {
      //   question: "Klassifikasiyanın əhəmiyyəti nədir?",
      //   answer:
      //     "Klassifikasiya prosesi ilə turizm sənayesi nümayəndəsinin təsnifatı və fəaliyyət istiqaməti aydınlaşdırılır. Əlavə olaraq minimum tələblərə cavab verdiyi müəyyən edilir. Bununla Turizm Reyestri vasitəsilə əməkdaşlıq etmək istəyən şirkətlərə, digər nümayəndələrin minimum tələblərə cavab verməsi ilə bağlı məlumat əldə etməsi imkanı yaradılır və B2B əlaqələndirmə portalı formalaşdırılması təmin edilir.",
      // },
      {
        question: "faq.classification_question_3",
        answer: "faq.classification_answer_3",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },
      {
        question: "faq.classification_question_4",
        answer: "faq.classification_answer_4",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },
      // {
      //   question:
      //     "Klassifikasiyanı tamamlamaq hər hansı bir nişan əldə etmək imkanı yaradırmı?",
      //   answer:
      //     "Klassifikasiyanı tamamlayan turizm sənayesi nümayəndəsi, “C”  və minimum standartlara cavab verməsini təsdiqləyən sənaye üzrə müvafiq nişanə əldə edir.",
      // },
      {
        question: "faq.classification_question_5",
        answer: "faq.classification_answer_5",
        // answer:
        //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      },

      // {
      //   question:
      //     "Klassifikasiyanı tamamlamaq hər hansı bir nişan əldə etmək imkanı yaradırmı?",
      //   answer:
      //     "Klassifikasiyanı tamamlayan turizm sənayesi nümayəndəsi, “C”  və minimum standartlara cavab verməsini təsdiqləyən sənaye üzrə müvafiq nişanə əldə edir.",
      // },

      // {
      //   question: "faq.classification_question_6",
      //   answer: "faq.classification_answer_6",
      //   // answer:
      //   //   "Sistemə vahid identifikasiya üsulu olan ASAN loginlə giriş etmək mümkündür. Sonrakı mərhələdə DVX-nin AVİS-indən razılıq əsasında müvafiq məlumatlar alınır və qeydiyyat üzrə müəyyən xanalar avtomatlaşdırılmış şəkildə doldurularaq qeydiyyat prosesi sürətləndirilir. Qeydiyyat prosesinin təsdiqlənməsi ilə şəxsi kabinetə giriş təmin edilmiş olur.",
      // },
      // {
      //   question: "Klassifikasiya məlumatlarını yeniləmək mümkündürmü?",
      //   answer:
      //     "Şəxsi kabinetin istifadəçi adı bölməsindən yeniləmə üzrə müraciət ünvanlamaq mümkündür. Müraciət təsdiqləndikdən sonra hesab üzrə məlumatların təkmilləşdirilməsi həyata keçəcəkdir.",
      // },
    ],
  },
  {
    _id: "3",
    key: "label.labels",
    list_name: "event_accordion_name_1",

    desc: [
      {
        question: "faq.label_question_1",
        answer: "faq.label_answer_1",
      },
      // {
      //   question: "Qiymətləndirmə prosesinə necə müraciət etmək mümkündür?",
      //   answer:
      //     "Qiymətləndirmə prosesi şəxsi kabinetdən istifadəçi adı altındakı qiymətləndirməyə “müracət et” hissəsindən həyata keçirilir.",
      // },
      // {
      //   question: "Qiymətləndirmə kateqoriyaları hansılardır?",
      //   answer:
      //     "Qiymətləndirmə kateqoriyaları A,B,C və D olaraq bölünməktədir..",
      // },
      {
        question: "faq.label_question_2",
        answer: "faq.label_answer_2",
      },
      {
        question: "faq.label_question_3",
        answer: "faq.label_answer_3",
      },
      // {
      //   question:
      //     "Qiymətləndirmə prosesində kateqoriyalardan əlavə keyfiyyət nişanları mövcuddurmu?",
      //   answer:
      //     "Qiymətləndirmə üzrə A və B kateqoriyalarının keyfiyyət nişanları mövcuddur. B kateqoriyasının altında korporativ biznes, keyfiyyət, təhlükəsizlik, turist dostu, şəffaf biznes və müştəri xidmətləri nişanları yer alır. A kateqoriyasının altında isə onlayn profil və tanıtım, onlayn platformalar, müştəri münasibətlərinin idarə edilməsi və riskin idarəedilməsi alt bölmələri müvafiq olaraq nişanlarla təmin edilmişdir.",
      // },

      // {
      //   question: "Klassifikasiya məlumatlarını təkmilləşdirmək mümkündürmü?",
      //   answer:
      //     "Şəxsi kabinetin istifadəçi adı bölməsindən təkmilləşdirmə üzrə müraciət ünvanlamaq mümkündür. Müraciət təsdiqləndikdən sonra hesab üzrə məlumatların təkmilləşdirilməsi həyata keçəcəkdir.",
      // },
      {
        question: "faq.label_question_4",
        answer: "faq.label_answer_4",
      },

      {
        question: "faq.label_question_5",
        answer: "faq.label_answer_5",
      },
      // {
      //   question: "Keyfiyyət nişanlarının etibarlıq müddəti mövcuddurmu?",
      //   answer:
      //     "Keyfiyyət nişanları alındığı tarixdən 1 (bir) il müddətinə verilir. Daha sonra onlayn qaydada monitorinqlə müddət yenidən uzadıla bilər.",
      // },
      {
        question: "faq.label_question_6",
        answer: "faq.label_answer_6",
      },
      // {
      //   question: "Şəkilləri hansı tələblərə uyğun yüklənməlidir?",
      //   answer:
      //     "Şəkillərlə bağlı tələblər, nümunələr və hansı şəkil tərzlərindən yayınmanızla əlaqədar tövsiyyələr yükləmə hissəsinin ipucu bölməsində detallı şəkildə ifadə olunmuşdur.",
      // },

      {
        question: "faq.label_question_7",
        answer: "faq.label_answer_7",
      },
      // {
      //   question:
      //     "Reklam xarakterli, fərqli formatlı şəkillərdən istifadə etmək mümkündür mü?",
      //   answer:
      //     "Klassifikasiya prosesi ilə turizm sənayesi nümayəndəsinin təsnifatı və fəaliyyət istiqaməti aydınlaşdırılır. Əlavə olaraq minimum tələblərə cavab verdiyi müəyyən edilir. Bununla Turizm Reyestri vasitəsiylə əməkdaşlıq etmək istəyən şirkətlərə, digər nümayəndələrin minimum tələblərə cavab verməsi ilə bağlı məlumat əldə etməsi imkanı yaradılır və B2B əlaqələndirmə portalı formalaşdırılması təmin edilir",
      // },
      {
        question: "faq.label_question_8",
        answer: "faq.label_answer_8",
      },
      // {
      //   question: "Klassifikasiya məlumatlarını təkmilləşdirmək mümkündürmü?",
      //   answer:
      //     "Şəkillər Turizm Reyestrində yerləşdirildiyindən, sistemin düzgün çalışması, dizayn tələblərinin pozulmaması və düzgün rəqabətlilik mühitinin təmin edilməsi üçün məlumatlandırıcı ipucu hissəsində yer alan tələblərə riayət olunmaqa yüklənməlidir.",
      // },
    ],
  },
  {
    _id: "4",
    key: "key.reestr",
    list_name: "event_accordion_name_1",
    desc: [
      // {
      //   question: "Sistem daxilində neçə tip reyestr mövcuddur?",
      //   answer:
      //     "Sistem daxilində sənaye tərəfdaşları, turizm resursları və tədbir/təlimlərin yer aldığı 3 tip reyestr mövcuddur.",
      // },
      {
        question: "faq.advantage_question_1",
        answer: "faq.advantage_answer_1",
      },
      // {
      //   question: "Resurs reyestri bizə nə imkanlar yaradır?",
      //   answer:
      //     "Turizm resursları reyestri ilə biznesiniz üçün yeni imkanlar və istiqamətlər müəyyən etmək, investisiya imkanlarını dəyərləndirmək və ümumilikdə ölkənin resurs potensialı ilə bağlı dolğun məlumat əldə etmək imkanı qazanırsınız.",
      // },
      {
        question: "faq.advantage_question_2",
        answer: "faq.advantage_answer_2",
      },
      // {
      //   question: "Sənaye tərəfdaşları reyestrinə necə düşmək olar?",
      //   answer:
      //     "Turizm informasiya və monitorinq sistemində qeydiyyat keçməklə reyestrdə mövcudluğunuzu təmin etmiş olursunuz., keyfiyyət, təhlükəsizlik, turist dostu, şəffaf biznes və müştəri xidmətləri nişanları yer alır. A kateqoriyasının altında isə onlayn profil və tanıtım, onlayn platformalar, müştəri münasibətlərinin idarə edilməsi və riskin idarəedilməsi alt bölmələri müvafiq olaraq nişanlarla təmin edilmişdir.",
      // },\
      {
        question: "faq.advantage_question_3",
        answer: "faq.advantage_answer_3",
      },

      // {
      //   question:
      //     "Təqdim olunan bütün məlumatlar sənaye tərəfdaşları reyestrində əks olunurmu?        ",
      //   answer:
      //     "Xeyr. Sadəcə açıqlanması hüquqi baxımdan qanuni olan, fərdi və kommersiya məlumatı kateqoriyasına daxil olmayan məlumatlar əks olunmaqdadır..",
      // },
      {
        question: "faq.advantage_question_4",
        answer: "faq.advantage_answer_4",
      },

      // {
      //   question: "Tədbir və təlim reyestri bizə nə imkanlar yaradır?",
      //   answer:
      //     " ReyestrSistem daxilində neçə tip reyestr mövcuddur?Sistem daxilində sənaye tərəfdaşları, turizm resursları və tədbir/təlimlərin yer aldığı 3 tip reyestr mövcuddur.Resurs reyestri bizə nə imkanlar yaradır?Turizm resursları reyestri ilə biznesiniz üçün yeni imkanlar və istiqamətlər müəyyən etmək, investisiya imkanlarını dəyərləndirmək və ümumilikdə ölkənin resurs potensialı ilə bağlı dolğun məlumat əldə etmək imkanı qazanırsınız. Sənaye tərəfdaşları reyestri bizə nə imkan yaradır?Sənaye tərəfdaşları reyestri ilə turizm sənayesi nümayəndələri haqqında ətraflı məlumat almaq və etibarlı partnyorlarla əməkdaşlıq imkanı əldə edirsiniz. Sənaye tərəfdaşları reyestrinə necə düşmək olar?Turizm informasiya və monitorinq sistemində qeydiyyat keçməklə reyestrdə mövcudluğunuzu təmin etmiş olursunuz.Təqdim olunan bütün məlumatlar sənaye tərəfdaşları reyestrində əks olunurmu?Xeyr. Sadəcə açıqlanması hüquqi baxımdan qanuni olan, fərdi və kommersiya məlumatı kateqoriyasına daxil olmayan məlumatlar əks olunmaqdadır.Tədbir və təlim reyestri bizə nə imkanlar yaradır?Tədbir və təlimlərin reyestrindən (təqvimindən) istifadə edərək müxtəlif turizm əhəmiyyətli tədbir/təlimləri iştirak etmək və tədbirlər sponsor/partnyor/tərəfdaş olmaq imkanı əldə edirsiniz.Sənaye tərəfdaşları reyestrindən çıxarıla bilərəmmi?Sənaye tərəfdaşları reyestrindən heç bir halda çıxarılmırsınız. Sistem üzrə tələblər qarşılanmadığı təqdirdə əldə etdiyiniz nişanlar ləğv edilir və  “C” kateqoriyasına qaytarılırsınız.",
      // },
      {
        question: "faq.advantage_question_5",
        answer: "faq.advantage_answer_5",
      },
      {
        question: "faq.advantage_question_6",
        answer: "faq.advantage_answer_6",
      },

      // {
      //   question:
      //     "Sənaye tərəfdaşları reyestrindən çıxarıla bilərəmmi?        ",
      //   answer:
      //     "Sənaye tərəfdaşları reyestrindən heç bir halda çıxarılmırsınız. Sistem üzrə tələblər qarşılanmadığı təqdirdə əldə etdiyiniz nişanlar ləğv edilir və  “C” kateqoriyasına qaytarılırsınız.",
      // },
    ],
  },

  {
    _id: "5",
    key: "menu_tourism_oportunity",
  },
  {
    _id: "6",
    // key: "    menu_events_trainings",
    _id2: "1",
    key: "faq.event",
    list_name: "event_accordion_name_1",
    desc: [
      {
        question: "faq.event_question_1",
        answer: "faq.event_answer_1",
      },
      // {
      //   question: "Tədbir yaratmaq üçün nə etməliyəm?",
      //   answer:
      //     "Tədbir yaratmaq üçün şəxsi kabinetin əsas panel hissəsindən tədbirlər düyməsi sıxılır. Açılan pəncərədən tədbir yaratmaq üçün qarşınıza çıxan şablon üzrə tələb olunan məlumatlar doldurulur və sistem inzibatçısına baxılması üçün göndərilir.",
      // },

      // {
      //   question: "Tədbirdə iştirak etmək üçün nə etməliyəm?",
      //   answer:
      //     "Tədbirdə iştirak etmək üçün ya şəxsi kabinetdən ya da tədbirlər hissəsindən istənilən tədbirə müraciət edilə bilər. Müraciətə baxılması üçün inzibatçıya yönləndirilir. Müraciət qənaətbəxş hesab edildiyi təqdirdə istifadəçi əlavə məlumatları növbəti şablonda təmin edir və müqaviləni ASAN İmza ilə təsdiqləyir. Sonrakı mərhələdə ASANPAY vasitəsi ilə ödəniş həyata keçirir və müvafiq elektron qəbz əldə edir. Növbəti mərhələdə tədbirdə iştirakla bağlı dəstək məktubu ilə birgə iştirak etmək imkanı əldə etmiş olur.          ",
      // },
      {
        question: "faq.event_question_2",
        answer: "faq.event_answer_2",
      },

      // {
      //   question:
      //     "Sistem üzrə hər hansı tədbirə sponsor/partnyor/tərəfdaş olmaq mümkündür?",
      //   answer:
      //     "Hər hansı sponsor/partnyor/tərəfdaşlıq müraciəti üzrə, əsas panelin tədbirlər hissəsindən müvafiq tədbir seçilir və tədbirə sponsor/partnyor/tərəfdaş olmaq düyməsi sıxılır. Tələb olunan şablon doldurulduqdan sonra baxılması üçün göndərilir.",
      // },
      {
        question: "faq.event_question_3",
        answer: "faq.event_answer_3",
      },
      {
        question: "faq.event_question_4",
        answer: "faq.event_answer_4",
      },
      // {
      //   question:
      //     "Yaratdığım tədbirlər təsdiqləndikdən sonra nə baş verir?          ",
      //   answer:
      //     "Yaradılmış tədbirlər tədbir/təlimlərin əks olunduğu təqvimdə öz əksini tapır.          ",
      // },
      {
        question: "faq.event_question_5",
        answer: "faq.event_answer_5",
      },

      // {
      //   question:
      //     "Tədbirlərimə müraciəti sistem üzərindən qəbul edə bilirəmmi?",
      //   answer:
      //     "Tədbirlərə müraciət funksionallığı istifadəçilərə bağlanmışdır.",
      // },
      {
        question: "faq.event_question_6",
        answer: "faq.event_answer_6",
      },
      // {
      //   question: "Tədbir hissəsini necə istifadə edə bilərəm?          ",
      //   answer:
      //     "Tədbir hissəsinə əsas panel hissəsindən daxil olaraq tədbir yarada, yaradılmış tədbirlərdə iştirak edə, onlarla sponsor/partnyor/tərəfdaşlıq  münasibətləri formalaşdırmaq imkanı əldə edə bilərsiniz.",
      // },
    ],
  },
  {
    _id: "6",
    _id2: "2",
    key: "faq.training",
    list_name: "event_accordion_name_1",
    desc: [
      // {
      //   question: "Təlimləri necə yarada bilərəm?        ",
      //   answer:
      //     "Şəxsi kabinetdə, əsas panelin alt hissəsində yer alan təlim bölməsindən istifadə edərək, təlim yarada bilərsiniz. Təlim yarat düyməsinə basaraq müvafiq şablona keçid alırsınız.  və təsdiqləndiyi təqdirdə ümumi tədbir/təlim təqviminə düşür.",
      // },
      {
        question: "faq.training_question_1",
        answer: "faq.training_answer_1",
      },
      {
        question: "faq.training_question_2",
        answer: "faq.training_answer_2",
      },
      // {
      //   question: "Təlimlərin siyahısını haradan görə bilərəm?",
      //   answer:
      //     "Təlimlərin siyahısını turizm təqvimləri hissəsindən görmək mümkündür.",
      // },
      // {
      //   question: "Təlimlərə necə  qoşula bilərəm?",
      //   answer:
      //     "Təlimlərə tədbir və təlim təqvimləri bölməsindən müvafiq təlimi seçməklə  qoşula bilərsiniz. Bu zaman müraciət et düyməsinə basılır və qarşıya çıxan şablon doldurulduqdan sonra baxılması üçün sistem inzibatçısına göndərilir.",
      // },
      {
        question: "faq.training_question_3",
        answer: "faq.training_answer_3",
      },
      // {
      //   question:
      //     "Qeydiyyat keçməmişəmsə tədbir və ya təlimlərdə iştirak edə bilərəmmi?",
      //   answer:
      //     " Qeydiyyatdan  keçmədiyiniz təqdirdə Turizm İnformasiya və Monitorinq Sisteminin tədbir və ya təlim hissəsindən qeydiyyatdan keçdikdən sonra yararlana biləcəksiniz.",
      // },
      // {
      //   question: "faq.training_question_3",
      //   answer: "faq.training_answer_3",
      // },

      // {
      //   question: "Məsləhət sorğusu",
      //   answer:
      //     "Əsas panel hissəsinin məsləhət sorğusu hissəsində yer alan bölmədən şikayət, məsləhət və investisiya layihəsi yönləndirə bilərsiniz. Müvafiq müraciətə uyğun cavablandırma birbaşa müvafiq işçidən cavablandırılaraq daxil olur. Bununla bağlı bildirişlər elektron qaydada emailinizə daxil olmaqdadır.",
      // },
      {
        question: "faq.training_question_4",
        answer: "faq.training_answer_4",
      },
      // {
      //   question:
      //     "Müraciət hissəsindən hansı tipli müraciətlər ünvanlaya bilərəm?",
      //   answer:
      //     "Şikayət, məsləhət və layihə ünvanlaya biləcəyiniz 3 tip müraciət imkanı mövcuddur.          ",
      // },
      {
        question: "faq.training_question_5",
        answer: "faq.training_answer_5",
      },
      {
        question: "faq.training_question_6",
        answer: "faq.training_answer_6",
      },
      // {
      //   question: "Əvvəlki sorğularımı görə bilərəmmi?",
      //   answer:
      //     "Əvvəlki sorğularınızı, şəxsi kabinet hissəsinin müraciətlər bölməsində mənim müraciətlərim adı altında görmək imkanınız mövcuddur. ",
      // },
      // {
      //   question: "Müraciətə hansı vaxt aralığında baxılacaqdır?",
      //   answer: "Müraciətə iş günü aralığında baxılacaqdır.",
      // },
      {
        question: "faq.training_question_6",
        answer: "faq.training_answer_6",
      },
    ],
  },
  {
    _id: "7",
    key: "key_statics",
    list_name: "event_accordion_name_1",
    desc: [
      // {
      //   question: "Statistika modulundan necə istifadə edə bilərəm?",
      //   answer:
      //     "Statistika modulundan istifadə üçün əsas səhifənin statistika başlığına tıklanılır. Növbəti açılan səhifədə “powerBI” istifadə edilərək formalaşdırılmış alət üzərindən ölkə üzrə statistik göstəricilərdən istifadə etmək imkanı vardır.",
      // },
      {
        question: "faq.statistic_question_1",
        answer: "faq.statistic_answer_1",
      },
      {
        question: "faq.statistic_question_2",
        answer: "faq.statistic_answer_2",
      },

      // {
      //   question: "Statistika modulu nə imkanlar yaradır?",
      //   answer:
      //     "Statistika modulundan istifadə edərək turizm üzrə statistik göstəriciləri əldə edə, formalaşan trendlərə uyğun düzgün təkliflər yarada ",
      // },

      // {
      //   question: "Modulun əsas məqsədi nədir?",
      //   answer:
      //     "Modulun əsas məqsədi bütün istifadəçilərə açıq olan, turizm statistikasının rahat şəkilə əks olunduğu, asan istifadəli informativ xidmət yaratmaqla dolğun turizm statistikasının istifadəçilərə çatdırılmasıdır. ",
      // },

      {
        question: "faq.statistic_question_3",
        answer: "faq.statistic_answer_3",
      },

      // {
      //   question: "Modul üzrə məlumatların mənbələri əks olunur mu?",
      //   answer:
      //     "İstifadə olunan məlumatlar üzrə bütün mənbələr göstərilməkdədir.",
      // },
      {
        question: "faq.statistic_question_4",
        answer: "faq.statistic_answer_4",
      },

      // {
      //   question:
      //     "    Modul üzrə datanın yenilənmə intervalı hansı müddət arasındadır?",
      //   answer:
      //     "Məlumatların  növündən asılı olaraq aralıq real vaxt rejimindən başlayaraq maksimum 1 ay ərzində yenilənmə dövrü tamamlanmaqdadı          ",
      // },
      {
        question: "faq.statistic_question_5",
        answer: "faq.statistic_answer_5",
      },

      {
        question: "faq.statistic_question_6",
        answer: "faq.statistic_answer_6",
      },

      // {
      //   question: "   Data hansı qurumlardan əldə edilir?",
      //   answer: `   Məlumatlar emal olunmuş və olunmamış olaraqdan aşağıdaki qurumlardan əldə edilir:
      //     	Azərbaycan Respublikası Mərkəzi Bankı
      //     	Azərbaycan Respublikasının Dövlət Sərhəd Xidməti
      //     	Azərbaycan Respublikasının Dövlət Miqrasiya Xidməti
      //     	Azərbaycan Respublikası Dövlət Statistika Komitəsi
      //     	Azərbaycan Respublikasının Prezidenti yanında Vətəndaşlara Xidmət və Sosial İnnovasiyalar üzrə Dövlət Agentliyi
      //     	Azərbaycan Respublikasının İqtisadiyyat Nazirliyi yanında Dövlət Vergilər Xidməti`,
      // },

      {
        question: "faq.statistic_question_7",
        answer: "faq.statistic_answer_7",
      },

      // {
      //   question:
      //     " Statistika modulundan hansı növdə datalar əldə etmək mümkün olacaq?",
      //   answer: `Statistika modulunda aşağıdaki növdə məlumatlardan yararlanmaq imkanı vardır:

      //   Kateqoriya: Gəlmə turizmi${"\n"}
      //   Verilənlər şəklində məlumatlar
      //   	Azərbaycana gələn əcnəbilərin və vətəndaşlığı olmayan şəxslərin sayı (ölkə, yaş, cins, qalma müddəti, nəqliyyat vasitələrinə əsasən)
      //   	Turizm məqsədi ilə Azərbaycana səyahət edən əcnəbilərin və vətəndaşlığı olmayan şəxslərin sayı (yaş, cins, nəqliyyat növləri, səfər müddəti və ziyarətin məqsədinə əsasən)
      //   	Gecələmələr (yerləşmə vasitələrinə əsasən)
      //   	Mədaxillər - nağd və nağdsız ödənişlər ((i) məqsəd və ii) ölkələrə ayırmaqla)
      //   	Mədaxillər (yalnız nağdsız  ödənişlər)
      //   Kateqoriya: Daxili turizm
      //   Verilənlər şəklində məlumatlar
      //   Turist səfərləri edənlərin sayı (cins və yaşa əsasən)
      //   Səfərlərin sayı (istifadə olunan yer, nəqliyyat vasitələri, təyinat məntəqələrinə ayırmaqla ziyarətin məqsədinə əsasən)
      //   Xərclər ((i) məqsəd və ii) ölkələrə ayırmaqla)
      //   Bir günlük səfərlərin sayı (ziyarətin məqsədi, xərclərə əsasən)
      //   Kateqoriya: Getmə turizmi
      //   Verilənlər şəklində məlumatlar
      //   	Xaricə gedən Azərbaycan vətəndaşlarının sayı (təyinat məntəqəsi, yaş, cins, qalma müddəti, nəqliyyat vasitələri, səfərin məqsədinə əsasən)
      //   	Xərclər
      //   	Turizm məqsədi ilə xarici ölkələrə səyahət edən bir günlük ziyarətçilərin sayı (yaş, cins, səfər məqsədi və xərclərə əsasən)
      //   Kateqoriya: Turizm sənayesi üçün
      //   Verilənlər şəklində məlumatlar
      //   •	Mehmanxana və mehmanxana tipli müəssisələrdə yerləşdirilən insanların sayı
      //   •	Mehmanxana və mehmanxana tipli müəssisələrdə gecələmələrin sayı
      //   •	Mehmanxana və mehmanxana tipli müəssisələrdə yerləşdirilən əcnəbilərin sayı
      //   •	Bölgə üzrə mehmanxana və mehmanxana tipli müəssisələrin gəlirləri (gəlir strukturu ilə)
      //   •	Mehmanxana və mehmanxana tipli müəssisələrin xərcləri (xərc  strukturu ilə)
      //   •	Mehmanxana və mehmanxana tipli müəssisələrin doluluq səviyyəsi
      //   •	Mehmanxana və mehmanxana tipli müəssisələrdə işçilərin sayı
      //   Planlaşdırılmış məlumatlar:
      //   Kateqoriya:  Gəlmə turizmi
      //   Verilənlər şəklində məlumatlar
      //   •	Azərbaycana gələn əcnəbilərin və vətəndaşlığı olmayan şəxslərin sayı (ölkə, yaş, cins, qalma müddəti, nəqliyyat vasitələrinə əsasən)
      //   •	Turizm məqsədi ilə Azərbaycana səyahət edən əcnəbilərin və vətəndaşlığı olmayan şəxslərin sayı (yaş, cins, nəqliyyat növləri, səfər müddəti və ziyarətin məqsədinə əsasən)
      //   •	Gecələmələr (yerləşmə vasitələrinə əsasən)
      //   •	Mədaxillər - nağd və  nağdsız ödənişlər ((i) məqsəd və ii) ölkələr üzrə bölgü)
      //   •	ASAN viza gəlmələri (ölkə, yaş, cins, nəqliyyat növləri, səfər müddəti və ziyarətin məqsədinə əsasən)
      //   Kateqoriya: Turizm sənayesi üçün
      //   Verilənlər şəklində məlumatlar
      //   •	Qurulan otellərin sayı
      //   •	Mehmanxana və mehmanxana tipli müəssisələrdə yerləşdirilən insanların sayı
      //   •	Mehmanxana və mehmanxana tipli müəssisələrdə gecələmələrin sayı
      //   •	Mehmanxana və mehmanxana tipli müəssisələrdə yerləşdirilən əcnəbilərin sayı
      //   •	Bölgə üzrə mehmanxana və mehmanxana tipli müəssisələrin gəlirləri (gəlir strukturu ilə)
      //   •	Mehmanxana və mehmanxana tipli müəssisələrin xərcləri (xərc  strukturu ilə)
      //   •	Mehmanxana və mehmanxana tipli müəssisələrin xərcləri (xərc  strukturu ilə)müəssisələrin doluluq səviyyəsi
      //   •	Mehmanxana və mehmanxana tipli müəssisələrdə işçilərin sayı
      //   Kateqoriya: Digər məlumatlar
      //   Verilənlər şəklində məlumatlar
      //   •	Booking.com məlumatları
      //   •	Airbnb məlumatları
      //   •	Bölgələrdəki hava şəraiti məlumatları
      //   •	Diqqətəlayiq yerlər
      //   •	Yerin adı
      //   •	Yerin məkanı (uzunluq və en dairəsi)
      //   •	Yerin fotoşəkli
      //   •	Yerin yaradılma tarixi
      //   •	Yerin növü (kilsə, teatr və s.)
      //   •	Yerin üslubu
      //   •	Və s.
      //   `,
      // },

      // {
      //   question: "Datalar qarşılıqlı fəaliyyət üçün yararlıdırmı?",
      //   answer:
      //     "Bütün məlumatlar maksimal şəkildə qarşılıqlı fəaliyyətin təşkili istiqamətində gruplaşdırılmışdır.",
      // },
      {
        question: "faq.statistic_question_8",
        answer: "faq.statistic_answer_8",
      },

      {
        question: "faq.statistic_question_9",
        answer: "faq.statistic_answer_9",
      },

      // {
      //   question: "Statistika modulu hərkəsin istifadəsinə açıqdırmı?",
      //   answer: "Statistika modulu bütün istifadəçilərə açıqdır.",
      // },
    ],
  },
  {
    _id: "8",
    key: "men.checking_module",
  },
  {
    _id: "9",
    key: "key_map",
    list_name: "event_accordion_name_1",
    desc: [
      // {
      //   question: "Turizm resursları xəritəsindən necə istifadə edə bilərəm?",
      //   answer:
      //     "Turizm resursları xəritəsindən filtr imkanları ilə istifadə etmək mümkündür, əlavə olaraq sistemdə reyestr və xəritənin birgə istifadə edildiyi axtarış üsulu mövcuddur. Bu funksionallıq ilə istənilən resurs haqqında həm xüsusiyyət həmdə məkan məlumatları almaq mümkün olacaqdır.",
      // },
      {
        question: "faq.map_question_1",
        answer: "faq.map_answer_1",
      },

      // {
      //   question: "Sənaye tərəfdaşları xəritəsindən necə istifadə edə bilərəm?",
      //   answer:
      //     "Sənaye tərəfdaşları xəritəsindən istifadə edərək turizm sənayesi nümayəndələrinin hansı ərazi üzrə fəaliyyət göstərdiyini müəyyən edə bilər, turizm məhsulları formalaşdırarkən məhsul formalaşdıran xidmətləri buradan seçə bilərsiniz.",
      // },
      {
        question: "faq.map_question_2",
        answer: "faq.map_answer_2",
      },
      // {
      //   question:
      //     "Turizm imkanları xəritəsindən necə istifadə edə bilərəm?          ",
      //   answer:
      //     "Turizm imkanları xəritəsi ilə müxtəlif regionlar üzrə göstəriciləri müəyyən edib, bu istiqamətdə fəaliyyətinizi planlaşdıra, investisiya imkanları dəyərləndirilməsini apara bilərsiniz.          ",
      // },
      {
        question: "faq.map_question_3",
        answer: "faq.map_answer_3",
      },
      // {
      //   question: "Xəritələr hissəsinə hansı bölmələrdən daxil ola  bilərəm?",
      //   answer:
      //     "Xəritə hissəsinə əsas səhifənin xəritə bölməsindən ya da şəxsi kabinet hissəsinin xəritə bölməsindən yönlənmək mümkündür.",
      // },
      {
        question: "faq.map_question_4",
        answer: "faq.map_answer_4",
      },
      {
        question: "faq.map_question_5",
        answer: "faq.map_answer_5",
      },
      // {
      //   question:
      //     "Müəyyən bir turizm resursunun xəritə üzrərində tapılması üçün axtarış verə bilərəmmi?          ",
      //   answer:
      //     "Turizm resursunun axtarışı üçün xəritə və resurs reyestirinin birləşdirildiyi alətdən istifad edə bilərsiniz. Bunun üçün axtarış yerindən resursun adı yazılır və oxşar adlı resursların sıyahısı əldə edilir. Daha sonra dəqiq resurs xəritə üzərində axtarılmaqla tapılır.",
      // },
    ],
  },

  {
    _id: "10",
    key: "key_guests_registration",
    list_name: "event_accordion_name_1",
    desc: [
      {
        question: "faq.guest_question_1",
        answer: "faq.guest_answer_1",
      },
      // {
      //   question:
      //     "Qonaq Qeydiyyatı Sistemindən bir-başa istifadə etmək mümkündür mü?",
      //   answer:
      //     "Qonaq Qeydiyyatı, Turizm İnformasiya və Monitorinq Sisteminin alt modulu olub, vahid giriş sistemi əsasında çalışmaqdadır. Bunun üçün TİMS-də qeydiyyatınızın olması zəruridir. Qeydiyyatı tamamladıqdan sonra sistemə giriş imkanınız formalaşacaqdır. Bundan sonra şəxsi kabinetinizə daxil olub, oradan Qonaq Qeydiyyatını sistemin funksionallıqlarına uyğun həyata keçirməyiniz mümkündür.",
      // },
      // {
      //   question: "Qonaq qeydiyyatının aparılması məcburidir mi?",
      //   answer:
      //     "Yeni Turizm Haqqında qanunun 9.2 bəndinə əsasən, mehmanxana və mehmanxana tipli obyektlərin yerli və əcnəbi qonaqlar üzrə qeydiyyat qaydalarına uyğun olaraq 24 saat ərzində qeydiyyata alması məcburidir.",
      // },
      {
        question: "faq.guest_question_2",
        answer: "faq.guest_answer_2",
      },
      // {
      //   question:
      //     "Qonaq Qeydiyyatı Sistemindən istifadə üçün qeydiyyatdan keçilməlidirmi?",
      //   answer:
      //     "Qonaq Qeydiyyatı sistemində istifadə üçün Turizm İnformasiya və Monitorinq Sistemindən qeydiyyat keçilməsi zəruridir.",
      // },
      {
        question: "faq.guest_question_3",
        answer: "faq.guest_answer_3",
      },

      // {
      //   question: "Qonaq qeydiyyatı necə aparılır?",
      //   answer:
      //     "Qonaq qeydiyyatı sisteminə ASAN Loginlə daxil olunduqdan sonra əsas panel hissəsindən qonaq qeydiyyatının aparıldığı müvafiq pəncərə seçilir. Qonaq qeydiyyatı şablonunda tələb olunan məlumatlar müvafiq alət vasitəsi ilə (sənəd oxudulmaqla) yada manual qaydada sistemə daxil edilir. Məlumatlar İAMAS sistemi köməyi ilə yoxlanılır və qeydiyyat baxılması üçün inzibatçıya göndərilir",
      // },
      {
        question: "faq.guest_question_4",
        answer: "faq.guest_answer_4",
      },

      // {
      //   question: "Tələb olunan məlumat xanalarını necə doldurmalıyam?",
      //   answer:
      //     "Məlumat üzrə tələblər hər bir xananın qarşısında yer alan informativ ipucu hissəsindən əldə edilə bilər.          ",
      // },
      {
        question: "faq.guest_question_5",
        answer: "faq.guest_answer_5",
      },
    ],
  },
  {
    _id: "11",
    key: "key_general",
    list_name: "event_accordion_name_1",
    desc: [
      {
        question: "faq.general_question_1",
        answer: "faq.general_answer_1",
      },
      // {

      //   question: "Turizm İnformasiya və Monitorinq Sistemi nədir?",
      //   answer: `Turizm İnformasiya və Monitorinq Sistemi, Dövlət Turizm Agentliyinin turizm xidmətləri keyfiyyətinin artırılması, elektron xidmətlərin şaxələndirilməsi, turizm sənayesinin rəqəmsallaşdırılmış, vizuallaşdırılmış turizm əhəmiyyətli  məlumatlarla təmin olunması, turizm məlumat bazasının formalaşdırılması, dövlət elektron xidmətlərinin istifadəsi və əlçatanlığın asanlaşdırılması, turizm keyfiyyət nişanlarının verilməsi, fərqlənən turizm sənayesi nümayəndələrinin müxtəlif güzəşt metodları ilə təşviq edilməsi, dövlət xidməti istifadəçi təcrübəsinin təkmilləşdirilməsi, turizm sahəsində təlim və sertifikatların elektron platformalar üzərindən sürətləndirilməsi; turizmin müxtəli inkişaf tələblərinin (G2B, B2B və B2C) ünvanlandığı dövlət-özəl tərəfdaşlığının inkişaf etdirilməsi məqsədləri istiqamətində formalaşdırdığı elektron idarəetmə alətidir. `,
      // },
      // {
      //   question: "TİMS-dən necə istifadə edə bilərəm?",
      //   answer:
      //     "Qeydiyyat üçün data.tourism.gov.az elektron ünvanından  istifadə edə bilərsiniz. Növbəti səhifədə gələn ASAN İmza ilə identifikasiyadan sonra qeydiyyat şablonu doldurulur və müvafiq sorğu gönədilir. Sorğu təsdiqləndikdən sonra şəxsi kabinetə giriş həyata keçirilir. Əgər mövcud qeydiyyatınız varsa, obyekt seçimindən sonra birbaşa şəxsi istifadəçi kabinetinə yönləndirilirsiniz.",
      // },
      {
        question: "faq.general_question_2",
        answer: "faq.general_answer_2",
      },
      // {
      //   question:
      //     "Səhifənin yenilənməsi ilə əlaqədar bildiriş qəbul edirəm. Bunun səbəbi nədir?",
      //   answer:
      //     "Səhifənin yenilənməsi ilə əlaqədar tələb, təmin edilmiş məlumat üzrə son yenilənmə tarixinin keçməsi ilə əlaqədardır. Bu təqdirdə tələb olunan məlumatı yeniliyərək baxılmağa göndərməyiniz xahiş olunur. Məlumat tələblərə cavab verirsə növbəti bildirişlərə qədər məlumat yenilənməsi etməyiniz zəruri deyil.",
      // },

      {
        question: "faq.general_question_3",
        answer: "faq.general_answer_3",
      },
      // {
      //   question:
      //     "İstifadə əlaqəli fikirlərimlə bağlı necə geridönüş edə bilərəm?",
      //   answer:
      //     "Geridönüş üçün 848 Qaynar Xəttindən istifadə edə, ya da dəstək elektron məktubuna  fikirlərinizi göndərə bilərsiniz.",
      // },
      {
        question: "faq.general_question_4",
        answer: "faq.general_answer_4",
      },

      // {
      //   question: "Müraciət üzrə məlumatlarıma rədd cavabı gələ bilərmi?",
      //   answer:
      //     "Təqdim olunmuş məlumatın tələblərə uyğunluğundan asılı olaraq rədd cavabı almaq mümkündür. Bu təqdirdə müraciətinizə rədd cavabının açıqlandığı elektron məktub qəbul edirsiniz. Müvafiq düzəlişləri etdikdən sonra müraciətinizin təsdiqlənməsi üçün heç bir maneə qalmayacaqdır.",
      // },
      {
        question: "faq.general_question_5",
        answer: "faq.general_answer_5",
      },

      // {
      //   question:
      //     "Platformadan hansı cihazlar vasitəsi ilə istifadə etmək mümkündür?",
      //   answer:
      //     "Platformadan “desktop”, “laptop” və mobil cihazlar vasitəsi ilə yararlanmaq mümkündür.",
      // },
      {
        question: "faq.general_question_6",
        answer: "faq.general_answer_6",
      },

      // {
      //   question:
      //     "Platformadan istifadə ilə bağlı hansı dəstək kanallarından istifadə etmək mümkündür?",
      //   answer:
      //     "Bunun üçün DTA-nın 848 qaynar xəttindən və müvafiq email elektron poçt vasitəsindən istifadə etmək mümkündür.",
      // },
      {
        question: "faq.general_question_7",
        answer: "faq.general_answer_7",
      },
      // {
      //   question: "Platforma üzrə əlavə xüsusiyyətlər mövcuddurmu?",
      //   answer:
      //     "Platforma üzərindən statistik məlumatlarla tanış olmaq, Turizm Reyestrinin məlumatlarından istifadə etmək, ölkə üzrə turizm resursları və imkanları xəritələrindən yararlanmaq, müxtəlif tədbirlər yaratmaq, tədbirlərdə iştirak etmək, tədbirlərə sponsor/partnyor/tərəfdaş olmaq, tədbirin ödənişlərini etmək, təlimlərdə iştirak etmək, statistik platformadan istifadə etmək, ekspert məsləhəti almaq və s. kimi imkanlardan yararlanmaq mümkündür.",
      // },

      {
        question: "faq.general_question_8",
        answer: "faq.general_answer_8",
      },
      // {
      //   question: "Sistem üzərindən rezervasiyalar qəbul etmək mümkündürmü?",
      //   answer:
      //     "Sistem üzərindən rezervasiyaların qəbulu mümkün deyil. Rezervasiyalarla bağlı müraciətlərin Turizm reyestrindən mehman.az internet səhifəsinə yönləndirmə edilməklə həyata keçirilməsi mümkündür",
      // },
      {
        question: "faq.general_question_9",
        answer: "faq.general_answer_9",
      },
      // {
      //   question: "faq.general_question_9",
      //   answer: "faq.general_answer_9",
      // },
      // {
      //   question:
      //     "Turizm İnformasiya və Monitorinq Sistemindən istifadə ödənişlidir mi?",
      //   answer: "Sistemdən istifadə tam ödənişsizdir.",
      // },
    ],
  },
  // {
  //   _id: "4",
  //   list_name: "event_accordion_name_4",
  //   desc: "event_accordion_text_4",
  // },
  // {
  //   _id: "5",
  //   list_name: "event_accordion_name_5",
  //   desc: "event_accordion_text_5",
  // },
  // {
  //   _id: "6",
  //   list_name: "event_accordion_name_6",
  //   desc: "event_accordion_text_6",
  // },
  // {
  //   _id: "7",
  //   list_name: "event_accordion_name_7",
  //   desc: "event_accordion_text_7",
  // },
  // {
  //   _id: "8",
  //   list_name: "event_accordion_name_8",
  //   desc: "event_accordion_text_8",
  // },
  // {
  //   _id: "9",
  //   list_name: "event_accordion_name_9",
  //   desc: "event_accordion_text_9",
  // },
  // {
  //   _id: "310",
  //   list_name: "event_accordion_name_10",
  //   desc: "event_accordion_text_10",
  // },
];

export const gradeItems = [
  {
    _id: "id_a",
    levelName: "key_grade_level_A+",
    desc: [
      { value: "key_grade_A_option_1" },
      { value: "key_grade_A_option_2" },
      { value: "key_grade_A_option_3" },
      { value: "key_grade_A_option_4" },
      // { value: "key_grade_A_option_5" },
    ],
  },
  {
    _id: "id_b",
    levelName: "key_grade_level_A",
    desc: [
      { value: "key_grade_B_option_1" },
      { value: "key_grade_B_option_2" },
      { value: "key_grade_B_option_3" },
      // {value:'Xarici nümayəndəliklər vasitəsilə təbliğat'},
      { value: "key_grade_B_option_4" },
      // {value:'Onlayn platformalarda təbliğat(Azerbaijan.travel, meetinazerbaijan.com, tourismboard.az)'},
      // {value:'Turizm mükafatlandırmaları'},
      { value: "key_grade_B_option_5" },
    ],
  },
  {
    _id: "id_c",
    levelName: "key_grade_level_B",
    desc: [
      { value: "key_grade_C_option_1" },
      { value: "key_grade_C_option_2" },
      { value: "key_grade_C_option_3" },
      { value: "key_grade_C_option_4" },
    ],
  },
  {
    _id: "id_d",
    levelName: "key_grade_level_C",
    desc: [
      { value: "key_grade_D_option_1" },
      { value: "key_grade_D_option_2" },
      { value: "key_grade_D_option_3" },
      { value: "key_grade_D_option_4" },
      { value: "key_grade_D_option_5" },
      { value: "key_grade_D_option_6" },
      { value: "key_grade_D_option_7" },
      { value: "key_grade_D_option_8" },
      { value: "key_grade_D_option_9" },
    ],
  },
];

export const serviceItems = [
  {
    id: 1,
    icon: image1,
    title: "key_service_item_title_1",
    text: "key_service_item_text_1",
  },
  {
    id: 2,
    // icon: "../../assets/images/restaurant/1.png",
    icon: image2,
    title: "key_service_item_title_2",
    text: "key_service_item_text_2",
  },
  {
    id: 3,
    // icon: "../../assets/images/restaurant/1.png",
    icon: image3,
    title: "key_service_item_title_3",
    text: "key_service_item_text_3",
  },
  {
    id: 4,
    // icon: "../../assets/images/restaurant/1.png",
    icon: image4,
    title: "key_service_item_title_4",
    text: "key_service_item_text_4",
  },
];

export const missionList = [
  {
    id: 1,
    name: "key_mission_list_1",
  },
  {
    id: 2,
    name: "key_mission_list_2",
  },
  {
    id: 3,
    name: "key_mission_list_3",
  },
  {
    id: 4,
    name: "key_mission_list_4",
  },
  {
    id: 5,
    name: "key_mission_list_5",
  },
  {
    id: 6,
    name: "key_mission_list_6",
  },
];

export const eventTabTitle = [
  {
    key: "FUTURE_EVENTS",
    az: "Gələcək tədbirlər",
    en: "",
    ru: "",
  },
  {
    key: "ATENDED_EVENTS",
    az: "İştirak etdiyim tədbirlər",
    en: "",
    ru: "",
  },
  {
    key: "HELPERS_EVENTS",
    az: "Kömək etdiyim tədbirlər",
    en: "",
    ru: "",
  },
  {
    key: "PAST_EVENTS",
    az: "Keçmiş tədbirlər",
    en: "",
    ru: "",
  },
];
