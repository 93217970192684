import { createSlice } from "@reduxjs/toolkit";

export const industryCategorySlice = createSlice({
    name: 'industryCategory',
    initialState: [],
    reducers: {
        fetchIndustryCategories (state, action) {
            state.push(action.payload)
        },
    }
})

export const { fetchIndustryCategories } = industryCategorySlice.actions;
export default industryCategorySlice.reducer;