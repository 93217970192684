import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import translationsReducer from "../reducers/translations";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import industryCategoriesReducer from "../slices/industryCategoriesSlice";
import { reduxBatch } from "@manaflair/redux-batch";
import faqs from "./../faq/faqReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const storeEnhancers = composeEnhancers(applyMiddleware(thunk));
const sagaMiddleware = createSagaMiddleware();
// Add your next reducer for specific entity to below Object
const reducers = combineReducers({
  // translations: translationsReducer,
  industryCategories: industryCategoriesReducer,
  faqs,
});

const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }),
  sagaMiddleware,
];

const store = configureStore({
  reducer: reducers,

  // middleware: storeEnhancers,
  middleware,
  // storeEnhancers
  devTools: process.env.NODE_ENV !== "production",
  enhancers: [reduxBatch],
});
// sagaMiddleware.run()
// const store = createStore(reducers, storeEnhancers);
export default store;
