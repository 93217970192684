import { SET_TRANSLATIONS } from "../actions-types";
import { Grades } from "../../shared/constants";

const initialState = {
  messages: {
    az: {},
    en: {},
    ru: {}
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRANSLATIONS: {
      return {
        ...state,
        messages: action.payload
      }
    }
    default: {
      return state;
    }
  }
}

export default reducer;